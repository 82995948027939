import { render, staticRenderFns } from "./wait_room.vue?vue&type=template&id=dfe63c44&scoped=true&"
import script from "./wait_room.vue?vue&type=script&lang=js&"
export * from "./wait_room.vue?vue&type=script&lang=js&"
import style0 from "./wait_room.vue?vue&type=style&index=0&id=dfe63c44&prod&lang=css&scoped=true&"
import style1 from "./wait_room.vue?vue&type=style&index=1&id=dfe63c44&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dfe63c44",
  null
  
)

export default component.exports