import Vue from "vue";
import VueRouter from "vue-router";

import Home from "@/pages/web-view/index.vue"
import About from "@/pages/web-view/about.vue";
import AboutDetail from "@/pages/web-view/about_detail.vue";
import Contact from "@/pages/web-view/contact.vue";
import ProductDetail from "@/pages/web-view/product_detail.vue";
import Auction from "@/pages/web-view/auction/auction.vue";
import listAssetAuction from "@/pages/web-view/auction/list_asset.vue";
import AssetAuction from "@/pages/web-view/auction/auction_asset.vue";
import AuctionRoom from "@/pages/web-view/auctionRoom/auction_room.vue"
import AuctionDetail from "@/pages/web-view/auction/auction_detail.vue";
import News from "@/pages/web-view/news.vue";
import Newsdetail from "@/pages/web-view/news-detail.vue";
import ProductAuction from "@/pages/web-view/product_auction.vue";
import CateDetail from "@/pages/web-view/cate_detail.vue";
import Login from "@/pages/web-view/auth/login.vue"
import Register from "@/pages/web-view/auth/register.vue"
import Countdown from "@/pages/web-view/countdown/countdown.vue"
import PageNotFound from "@/pages/web-view/404.vue"
import Account from "@/pages/web-view/account/account.vue"
import PaymentHistory from "@/pages/web-view/account/paymentHistory.vue"
import PaymentRequest from "@/pages/web-view/account/paymentRequest.vue"
import RegisterAuction from "@/pages/web-view/registerAuction.vue"

//Layout
import userView from "@/layouts/userView.vue"
import adminView from "@/layouts/adminView.vue"
import acutioneerView from "@/layouts/auctioneer.vue"
//Admin
import loginAdmin from "@/pages/admin-view/components/login.vue"
import Dashboard from "@/pages/admin-view/dashboard"
import branchManager from "@/pages/admin-view/branch"
// Tài sản
import addAsset from "@/pages/admin-view/assetManager/add_asset.vue"
import editAsset from "@/pages/admin-view/assetManager/edit_asset.vue"
import approveAsset from "@/pages/admin-view/assetManager/approve_asset.vue"
import listAsset from "@/pages/admin-view/assetManager/list_asset.vue"
import draftAsset from "@/pages/admin-view/assetManager/draft_asset.vue"
import detailAsset from "@/pages/admin-view/assetManager/asset_detail.vue"
//Nhân viên
import employAuth from "@/pages/admin-view/employManager/employ_auth.vue"
import listEmploy from "@/pages/admin-view/employManager/list_employ.vue"
//Khách hàng
import listCusNew from "@/pages/admin-view/customer/list_cus_new.vue"
import listCus from "@/pages/admin-view/customer/list_customer.vue"
import listGuest from "@/pages/admin-view/customer/list_guest.vue"
//Cuộc đấu giá
import approveAuction from "@/pages/admin-view/auctionManager/approveAuction.vue"
import listAuction from "@/pages/admin-view/auctionManager/list_auction.vue"
import addAuction from "@/pages/admin-view/auctionManager/add_auction.vue"
import draftAuction from "@/pages/admin-view/auctionManager/draft_auction.vue"
import editAuction from "@/pages/admin-view/auctionManager/edit_auction.vue"
import listJoined from "@/pages/admin-view/auctionManager/list_cus_joined.vue"
import appJoined from "@/pages/admin-view/auctionManager/list_approve.vue"
import listHistory from "@/pages/admin-view/auctionManager/list_historyPay.vue"
//TIN TỨC
import appNews from "@/pages/admin-view/newsManager/new_news.vue"
import listNews from "@/pages/admin-view/newsManager/list_news.vue"
import listDraftNews from "@/pages/admin-view/newsManager/draft_news.vue"
import addNews from "@/pages/admin-view/newsManager/formAdd_news.vue"
import editNews from "@/pages/admin-view/newsManager/formEdit_news.vue"
import detailNews from "@/pages/admin-view/newsManager/detail_news.vue"
import adsList from "@/pages/admin-view/newsManager/list_ads.vue"
import addAds from "@/pages/admin-view/newsManager/formAdd_ads.vue"
//Hệ thống
import settings from "@/pages/admin-view/settings/index.vue"
//Báo cáo chung
import reports from "@/pages/admin-view/reports/index.vue"

//Auctioneer
import loginAu from "@/pages/admin-view/auctioneer/login.vue"
import auctioneer from "@/pages/admin-view/auctioneer"
import auctioneerRoom from "@/pages/admin-view/auctioneer/auctioneer_room.vue"
import waitRoom from "@/pages/admin-view/auctioneer/wait_room.vue"
import auDetail from "@/pages/admin-view/auctioneer/auction_detail.vue"
import assetDetail from "@/pages/admin-view/auctioneer/asset_detail.vue"
import listApprove from "@/pages/admin-view/auctioneer/manager/list_approve.vue"
import listAuthen from "@/pages/admin-view/auctioneer/manager/list_authen.vue"
import Manager from "@/pages/admin-view/auctioneer/manager"
import auManager from "@/pages/admin-view/auctioneer/manager/au_auction.vue"
import auListAsset from "@/pages/admin-view/auctioneer/manager/components/listasset.vue"
import reportPreview from "@/pages/admin-view/reportsPreview"
import auctionSession from "@/pages/admin-view/reportsPreview/auctionSession.vue"
import listMemberAuction from "@/pages/admin-view/reportsPreview/listMemberAuction.vue"
import auPage from "@/pages/admin-view/components/personal_page.vue"
Vue.use(VueRouter);

const DEFAULT_TITLE = "DGV";

const routes = [
    {
        path: "/",
        meta: { title: "Trang chủ", },
        component: userView,
        children: [
            {
                path: "/",
                component: Home,
                meta: { title: "Trang chủ" },
            },
            {
                path: "/login",
                meta: { title: "Đăng nhập", },
                component: Login,
            },
            {
                path: "/forget-password",
                meta: { title: "Quên mật khẩu", },
                component: ()=> import('@/pages/web-view/auth/forgetPassword.vue'),
            },
            {
                path: "/account",
                component: Account,

                meta: {
                    title: "Tài Khoản",
                    requiresAuth: true,
                },
                children: [
                    {
                        path: '',
                        meta: { title: "Thông tin tài khoản", },
                        children: [
                            {
                                path: 'banking',
                                meta: { title: "Thông tin tài khoản ngân hàng" },
                            },
                            {
                                path: 'file',
                                meta: { title: "File" },
                            },
                        ]
                    },
                    {
                        path: 'auction',
                        meta: {
                            title: "Cuộc đấu giá tham gia",
                        },
                        children: [
                            {
                                path: ':status',
                                meta: { title: "Cuộc đấu giá sắp diễn ra", },
                            },
                        ]
                    },

                    {
                        path: 'asset',
                        meta: { title: "Tài sản đấu giá", }
                    },
                ]
            },
            {
                path: "/payment-history/:id",
                component: PaymentHistory,
                meta: { title: "Lịch sử trả giá" }
            },
            {
                path: "/payment-request",
                component: PaymentRequest,
                meta: { title: "Kết quả trả giá" }
            },
            {
                path: "/countdown",
                component: Countdown,
                meta: { title: "Đếm ngược", },
            },
            {
                path: "/register",
                meta: { title: "Đăng ký", },
                component: Register,
            },
            {
                path: "/register-auction/:id",
                meta: { title: "Đăng ký đấu giá", },
                component: RegisterAuction,
            },
            {
                path: "/countdown",
                meta: { title: "Đếm ngược", },
                component: Countdown,
            },
            {
                path: "/about",
                component: About,
                meta: { title: "Giới thiệu", },
            },
            {
                path: "/detail/:infomation",
                component: AboutDetail,
                meta: { title: "Hồ sơ", },
            },
            {
                path: "/contact",
                component: Contact,
                meta: { title: "Liên hệ", },
            },
            {
                path: "/product-detail/:asset_id",
                component: ProductDetail,
                meta: { title: "Thông tin đấu giá", },
            },
            {
                path: "/auction",
                component: Auction,
                meta: { title: "Cuộc đấu giá", },
                children:[
                    {
                        path: "/auction/list_asset/:auction_id",
                        component: listAssetAuction,
                        meta: { title: "Tài sản trong cuộc đấu giá" }
                    },
                ]                
            },
            {
                path: "/auction/auction_asset/:auction_id",
                component: AssetAuction,
                meta: { title: "Tài sản trong cuộc đấu giá" },
            },
            
            {
                path:"/auction-room",
                component: ()=>import('@/pages/web-view/auctionRoom/index.vue'),
                meta: {
                    title: "Phòng đấu giá",
                    requiresAuth: true
                },
                children:[
                    {
                        path: ":id",
                        component: AuctionRoom,
                        meta: {
                            title: "Phòng đấu giá",
                            requiresAuth: true
                        },

                    },
                    {
                        path: "/finished-auction/:id",
                        meta: {
                            title: "Kết quả đấu giá",
                            requiresAuth: true
                        },
                        component: () => import('@/pages/web-view/auctionRoom/finishedRoom.vue')
                    },
                    
                    
                ]
            },
            {
                path: "/result-auction/:id",
                meta: {
                    title: "Kết quả cuộc đấu giá",
                    requiresAuth: true
                },
                component: () => import('@/pages/web-view/account/resultAuction.vue')
            },
            {
                path: "/auction-detail/:asset_id",
                component: AuctionDetail,
                meta: { title: "Thông tin chi tiết đấu giá", },
            },
            {
                path: "/news",
                component: News,
                meta: { title: "Tin tức", },
            },
            {
                path: "/news-detail/:news_id",
                component: Newsdetail,
                meta: { title: "Chi tiết tin tức", },
            },
            {
                path: "/product-auction",
                component: ProductAuction,
                meta: { title: "Tài sản đấu giá", },
            },
            {
                path: "/cate-detail/:category_id",
                component: CateDetail,
                meta: { title: "Chi tiết danh mục" },
            },
        ]
    },

    //ADMIN
    {
        path: "/loginAdmin",
        component: loginAdmin,
        meta: { title: "Màn hình đăng nhập quản trị viên" },
    },
    {
        path: "/admin",
        component: adminView,
        meta: 
            { 
                title: "Trang quản trị",
                checkAdm: true
            },
        children: [
            {
                path: "/admin",
                component: Dashboard,
                meta: { title: "Trang quản trị" },
            },
            {
                path: "branch",
                component: branchManager,
                meta: { title: "Quản lý chi nhánh" },
            },
            {
                path: "add-asset",
                component: addAsset,
                meta: { title: "Tạo tài sản mới" },
            },
            {
                path: "edit-asset/:asset_id",
                component: editAsset,
                meta: { title: "Chỉnh sửa tài sản" },
            },
            {
                path: "approve-asset",
                component: approveAsset,
                meta: { title: "Duyệt tài sản" },
            },
            {
                path: "list-asset",
                component: listAsset,
                meta: { title: "Danh sách tài sản" },
            },
            {
                path: "draft-asset",
                component: draftAsset,
                meta: { title: "Danh sách bản nháp tài sản" },
            },
            {
                path: "approve-asset/detail-asset/:asset_id",
                component: detailAsset,
                meta: { title: "Chi tiết tài sản" },
            },
            {
                path: "list-asset/detail-asset/:asset_id",
                component: detailAsset,
                meta: { title: "Chi tiết tài sản" },
            },
            {
                path: "employee-auth",
                component: employAuth,
                meta: { title: "Phân quyền nhân viên" },
            },
            {
                path: "list-employee",
                component: listEmploy,
                meta: { title: "Danh sách nhân viên" },
            },
            {
                path: "list-customer-new",
                component: listCusNew,
                meta: { title: "Duyệt khách hàng mới" },
            },
            {
                path: "list-customer",
                component: listCus,
                meta: { title: "Danh sách khách hàng" },
            },
            {
                path: "list-guest",
                component: listGuest,
                meta: { title: "Danh sách khách mời" },
            },
            {
                path: "approve-auction",
                component: approveAuction,
                meta: { title: "Duyệt cuộc đấu giá" },
            },
            {
                path: "list-auction",
                component: listAuction,
                meta: { title: "Danh sách cuộc đấu giá" },
            },
            {
                path: "draft-auction",
                component: draftAuction,
                meta: { title: "Bản nháp cuộc đấu giá" },
            },
            {
                path: "list-auction/detail-asset/:asset_id",
                component: detailAsset,
                meta: { title: "Chi tiết tài sản" },
            },
            {
                path: "add-auction",
                component: addAuction,
                meta: { title: "Thêm cuộc đấu giá" },
            },
            {
                path: "edit-auction/:auction_id",
                component: editAuction,
                meta: { title: "Sửa cuộc đấu giá" },
            },
            {
                path: "list-joined/:asset_id",
                component: listJoined,
                meta: { title: "Danh sách tham gia đấu giá" },
            },
            {
                path: "app-joined/:asset_id",
                component: appJoined,
                meta: { title: "Duyệt tham gia đấu giá" },
            },
            {
                path: "list-history/:asset_id",
                component: listHistory,
                meta: { title: "Lịch sử trả giá" },
            },
            {
                path: "app-news",
                component: appNews,
                meta: { title: "Duyệt tin tức mới" },
            },
            {
                path: "list-news",
                component: listNews,
                meta: { title: "Danh sách tin tức" },
            },
            {
                path: "draft-news",
                component: listDraftNews,
                meta: { title: "Danh sách bản nháp tin tức" },
            },
            {
                path: "add-news",
                component: addNews,
                meta: { title: "Tạo tin tức mới" },
            },
            {
                path: "edit-news/:news_id",
                component: editNews,
                meta: { title: "Chỉnh sửa tin tức mới" },
            },
            {
                path: "detail-news/:news_id",
                component: detailNews,
                meta: { title: "Chi tiết tin tức" },
            },
            {
                path: "list-ads",
                component: adsList,
                meta: { title: "Quảng cáo" },
            },
            {
                path: "add-ads",
                component: addAds,
                meta: { title: "Tạo quảng cáo mới" },
            },
            {
                path: "reports",
                component: reports,
                meta: { title: "Báo cáo chung" },
            },
            {
                path: "settings",
                component: settings,
                meta: { title: "Quản lý hệ thống" },
            },
        ]
    },

    //Auctionneer
    {
        path: "/loginAuctioneer",
        component: loginAu,
        meta: { title: "Màn hình đăng nhập đấu giá viên" },
    },
    {
        path: "/auctioneer",
        component: acutioneerView,
        meta: {
            title: "Màn hình đấu giá viên",
            checkAu: true
        },
        children: [
            {
                path: "/auctioneer",
                component: auctioneer,
                meta: { title: "Màn hình đấu giá viên" },

            },
            {
                path: "auction-room/:auction_id",
                component: auctioneerRoom,
                meta: { title: "Phòng đấu giá" },
            },

            {
                path: "auction",
                component: Manager,
                meta: { title: "Quản lý" },
                children: [
                    {
                        path: "/auctioneer/auction",
                        component: auManager,
                        meta: { title: "Cuộc đấu giá" },
                    },
                    {
                        path: "/auctioneer/auction/list-asset/:auction_id",
                        component: auListAsset,
                        meta: { title: "Danh sách tài sản" },
                    },
                    {
                        path: "/auctioneer/asset",
                        component: () => import('@/pages/admin-view/auctioneer/manager/asset.vue'),
                        meta: { title: "Quản lý tài sản" }
                    },
                    {
                        path: "add-new",
                        component: () => import('@/pages/admin-view/auctioneer/manager/auction/add-new.vue'),
                        meta: { title: "Tạo cuộc đấu giá mới" }
                    },
                    {
                        path: "edit/:id",
                        component: () => import('@/pages/admin-view/auctioneer/manager/auction/edit.vue'),
                        meta: { title: "Sửa cuộc đấu giá" }
                    }
                ]
            },
            {
                path: "/auctioneer/wait-room/:asset_id",
                component: waitRoom,
                meta: { title: "Điểm danh" }
            },
            {
                path: "/auctioneer/auctiondetail-auction/:asset_id",
                component: auDetail,
                meta: { title: "Chi tiết" }
            },
            {
                path: "/auctioneer/asset-detail/:asset_id",
                component: assetDetail,
                meta: { title: "Chi tiết tài sản đấu giá" }
            },
            {
                path: "/auctioneer/list-approve/:asset_id",
                component: listApprove,
                meta: { title: "Duyệt đăng ký tham gia đấu giá" }
            },
            {
                path: "/auctioneer/list-authen/:auction_id",
                component: listAuthen,
                meta: { title: "Duyệt ủy quyền tham gia đấu giá" }
            },
        ]
    },
    {
        path: "/admin/page",
        component: auPage,
        meta: { title: "Trang cá nhân", checkAdm: true }
    },
    {
        path: "/auctioneer/page",
        component: auPage,
        meta: { title: "Trang cá nhân", checkAu: true }
    },
    {
        path: "/report",
        component: reportPreview,
        children: [
            {
                path: "auctionSession",
                component: auctionSession
            },
            {
                path: "listMemberAuction",
                component: listMemberAuction
            }
        ]
    },
    {
        path: "*",
        component: PageNotFound,
        name: "PageNotFound",
    },
    {
        path: "/components",
        component: () => import('@/pages/web-view/components.vue'),
        name: "components",
    },
];


const router = new VueRouter({
    mode: "history",
    base: "/",
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return {
                selector: to.hash,
                behavior: 'smooth',
            };
        }
        else if (savedPosition) {
            return savedPosition;
        }
        else {
            return {
                x: 0, y: 0
            };
        }
    },
});
// Navigation guard
router.beforeEach((to, from, next) => {
    const isLoggedIn = localStorage.getItem('userInfo')
    if (to.matched.some(route => route.meta.requiresAuth) && !isLoggedIn) {
        next('/login');
    } else {
        next();
    }

    const isLoggedInAu = localStorage.getItem('auInfo')
    if (to.matched.some(route => route.meta.checkAu) && !isLoggedInAu) {
        next('/loginAuctioneer');
    } else {
        next();
    }

    const isLoggedInAdmin = localStorage.getItem('admInfo')
    if (to.matched.some(route => route.meta.checkAdm) && !isLoggedInAdmin) {
        next('/loginAdmin');
    } else {
        next();
    }
});

router.afterEach((to, from) => {
    Vue.nextTick(() => {
        document.title = to.meta.title || DEFAULT_TITLE;
    }

    );
}

);

export default router;