<template>
    <div>
        <b-modal ref="editSettings" centered hide-footer hide-header modal-class="form-setting"
            content-class="modal-setting" size="xl">
            <h5>Chỉnh sửa bài viết</h5>
            <b-form @submit="updateInfo" :class="{ 'overlay': isLoading }">
                <b-row class="align-items-center mb-3">
                    <b-col cols="lg-4"><label for="ttl">Tiêu đề tin tức:</label></b-col>
                    <b-col cols="lg-8"><b-form-input id="ttl" class="modal_ttl" v-for="(item, index) in data" :key="index"
                            v-if="item.type == check_type" v-model="item.title"></b-form-input></b-col>
                </b-row>
                <ckeditor :editor="editor" v-for="(item, index) in data" :key="index" v-if="item.type == check_type"
                    v-model="item.value" :config="editorConfig" @ready="onReady"></ckeditor>
                <b-row class="align-items-center mt-3" v-if="type !== 'company_introduction'">
                    <b-col cols="md-4"><label for="lnk">Link liên kết:</label></b-col>
                    <b-col cols="md-8"><b-form-input id="lnk" placeholder="Nhập link liên kết" v-for="(item, index) in data"
                            :key="index" v-if="item.type == check_type" v-model="item.url"></b-form-input></b-col>
                </b-row>
                <div class="text-right mt-3">
                    <button class="btn-cancel" type="button" @click="hideModal">Hủy bỏ</button>
                    <button type="submit" v-if="intListRole.includes(45)">Lưu bài viết</button>
                </div>
            </b-form>
            <div class="loader" v-if="isLoading"></div>
        </b-modal>
    </div>
</template>

<script>
// import Quill from 'quill';
import Editor from '@ckeditor/ckeditor5-build-decoupled-document';
import '@ckeditor/ckeditor5-build-classic/build/translations/vi';
import axios from 'axios'
export default {
    data() {
        function uploader(editor) {
            editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
                return uploadImage(loader);
            };
        }

        function uploadImage(loader) {
            return {
                upload: () => {
                    return new Promise((resolve, reject) => {
                        loader.file.then(async (file) => {
                            try {
                                if (!file) return

                                var data = {
                                    'file': file,
                                    'path': 'news/image/'
                                }

                                await axios.post(this.$axios + '/common/uploadFile', data, {
                                    headers: {
                                        'x-access-token': '',
                                        'Content-Type': 'multipart/form-data'
                                    }
                                }).then(res => {
                                    const url = this.$storage + res.data.list_result[0].path_tam
                                    resolve({ default: url })
                                })
                            } catch (error) {
                                reject(error)
                            }
                        })
                    })
                }
            }
        }
        return {
            type: '',
            data: [],
            token: '',
            check_type: '',
            listRole: [],
            intListRole: [],
            isLoading: false,
            editor: Editor,
            editorConfig: {
                extraPlugins: [uploader],
                language: 'vi',
                toolbar: {
                    items: [
                        'undo',
                        'redo',
                        '|',
                        'heading',
                        '|',
                        'bold',
                        'italic',
                        'strikethrough',
                        'underline',
                        '|',
                        'bulletedList',
                        'numberedList',
                        '|',
                        'outdent',
                        'indent',
                        '|',
                        'imageUpload',
                        'link',
                        '|',
                        'blockQuote',
                        'insertTable',
                        'alignment',
                        '|',
                        'fontFamily',
                        'fontSize',
                        'fontColor',
                        'fontBackgroundColor',
                    ]
                },
            },
            dataUpdate: ''
        }
    },
    mounted() {
        const storedData = localStorage.getItem('admInfo')
        if (storedData) {
            var data = JSON.parse(storedData)
            this.token = data.accessToken
            data.roles.arr_resource.forEach(element => {
                this.listRole.push(element.resource_id)
            });
            this.intListRole = this.listRole.map(Number)
        }
    },
    methods: {
        show(type, data) {
            this.$refs['editSettings'].show()
            this.type = type
            this.data = data
            this.$nextTick(() => {
                this.data.forEach(ele => {
                    if (type == ele.type) {
                        this.dataUpdate = ele.value
                        this.check_type = ele.type
                    }
                });
            });
        },
        hideModal() {
            this.$refs['editSettings'].hide()
        },
        emitModalVisible(isClose = false) {
            this.$emit("handleCloseModal", isClose);
        },
        async updateInfo() {
            event.preventDefault()
            const data = {
                data: this.data
            }
            this.isLoading = true
            await this.$axios.put('/common/updateInfomation', data, {
                headers: {
                    'x-access-token': this.token
                }
            }).then(res => {
                this.$toast.success('Lưu thông tin thành công!')
                this.$refs['editSettings'].hide()
                this.emitModalVisible(false)
                this.isLoading = false
            }).catch(err => {
                this.$toast.error('Lưu thông tin không thành công!')
                this.isLoading = false
            })
        },
        onReady(editor) {
            editor.ui.getEditableElement().parentElement.insertBefore(
                editor.ui.view.toolbar.element,
                editor.ui.getEditableElement()
            );
        },
    }
}
</script>

<style>
.form-setting .modal-setting {
    /* padding: 11px 16px 18px 16px; */
    color: #515151;
    border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}

.form-setting .modal-setting .editor {
    max-height: 500px;
    overflow-y: auto;
}

.form-setting .modal-setting .editor::-webkit-scrollbar {
    width: 0;
}

.ck-body-wrapper {
    position: relative;
    z-index: 1100;
}
</style>
<style lang="css" scoped>
.modal_ttl {
    background-color: #EEEEEE;
    font-size: 20px;
    font-weight: 700;
    border-radius: 15px;
    padding: 6px 20px 6px 12px;
}

input {
    border-radius: 15px;
    background-color: #EEE;
    border: none;
    padding: 21px 20px 20px 12px;
}

button {
    background-color: #EE3439;
    border-radius: 20px;
    color: #FFF;
    font-size: 14px;
    font-weight: 500;
    border: none;
    width: 138px;
    height: 30px;
}

button.btn-cancel {
    margin-right: 7px;
    border: 1px solid #EE3439;
    color: #EE3439;
    background-color: #FFF;
}

label {
    font-weight: 500;
}

input:focus {
    box-shadow: none;
    border: none;
    background: #EEEEEE;
}</style>