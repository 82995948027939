<template>
    <div class="tab-product-detail" :class="{'public': asset.public_status}">
        <b-tabs content-class="col-auto" :content-class="{'mt-3': !asset.public_status }">
            <b-tab title="Danh sách trả giá" active v-if="propisClient || asset.public_status" >
                <div :class="{ 'd-md-flex bid-list': asset.public_status }">
                    <div class="w-100 px-2">
                        <div class="text" v-if="!asset.public_status">
                            <p class="user_join">Số người tham gia đấu giá: {{ countMemberJoined }}</p>
                            <button><img src="@/assets/icon/Delivery Time.png" alt=""> Làm mới</button>
                        </div>
                        <div class="list_price" v-if=" asset.public_status && list_hightest">
                            <p class="ttl">Giá trả cao nhất:</p>
                            <div class="highest-price" v-for="(data, index) in list_hightest" v-if="index == 0">
                                <p>{{ data.member_code }}</p>
                                <p>{{ data.member_name }}</p>
                                <p>{{ formateTime(data.time) }}</p>
                                <p class="text-red">{{ parseInt(data.price).toLocaleString("vi-VN") }}VNĐ</p>
                            </div>
                        </div>
                        <div class="d-flex flex-between th" v-if="asset.public_status">
                            <div class="col-md-3 public">Mã khách hàng</div>
                            <div class="col-md-4 public">Họ và tên</div>
                            <div class="col-md-2 public">Thời gian trả giá</div>
                            <div class="col-md-3 public">Giá đã trả (VNĐ)</div>
                        </div>
                        <b-table responsive="md lists-bid" :fields="bid_list.fields" :items="all_bid" tbody-tr-class="my-2 bid-list" v-if="asset.public_status">
                            <template #cell(index)="data" :class="{'d-none': asset.public_status, 'return_price': row.item.withdraw_status == 1 }">
                                {{ data.index + 1 }}
                            </template>
                            <template #cell(member_code)="row" >
                                <span :class="{ 'return_price': row.item.kick == 1 }">
                                    {{ row.item.member_code }}
                                </span>
                            </template>
                            <template #cell(member_name)="row" >
                                <span :class="{ 'return_price': row.item.kick == 1 }">
                                    {{ row.item.member_name }}
                                </span>
                            </template>
                            <template #cell(price)="row">
                                <span :class="{ 'return_price': row.item.withdraw_status == 1 || row.item.kick == 1 }" v-if="row.item.price">
                                    {{ parseInt(row.item.price).toLocaleString("vi-VN") }} VNĐ
                                </span> <br>
                                <span v-show=" row.item.withdraw_status == 1" class="text-red">
                                    Rút giá
                                </span>
                            </template>
                            <template #cell(time)="row" >
                                <span :class="{ 'return_price': row.item.kick == 1 }">
                                    {{ formateTime(row.item.time) }}
                                </span>
                            </template>
                            <template #cell(status)="row" :class="{ 'return_price': row.item.withdraw_status == 1 }">
                                <span class="highest"
                                    v-if="(row.item.price == highest_price && row.item.withdraw_status == 0 && row.item.member_id == dataHighest.highest_bid.member_id)">
                                    <img src="@/assets/icon/Ok.png" style="width: 20px;height: auto;" alt="">
                                    Giá cao nhất
                                </span>
                                <span class="lower" v-else>
                                    <img src="@/assets/icon/close.png" style="width: 20px;height: auto;" alt="">
                                    Giá thấp hơn 
                                </span>
                            </template>
                        </b-table>
                        <b-table responsive="md" :fields="bid_list.fields" :items="top5AllBid" tbody-tr-class="my-2 bid-list" v-else>
                            <template #cell(index)="data" :class="{ 'd-none': asset.public_status }">
                                {{ data.index + 1 }}
                            </template>
                            <template #cell(price)="row">
                                <span :class="{ 'return_price': row.item.withdraw_status == 1 }" v-if="row.item.price">
                                    {{ parseInt(row.item.price).toLocaleString("vi-VN") }} VNĐ
                                </span> <br>
                                <span v-show="row.item.withdraw_status == 1" class="text-red">
                                    Rút giá
                                </span>
                            </template>
                            <template #cell(time)="row">
                                {{ formateTime(row.item.time) }}
                            </template>
                            <template #cell(status)="row">
                                <span class="highest"
                                    v-if="row.item.price == highest_price && row.item.withdraw_status == 0 && row.item.member_id == dataHighest.highest_bid.member_id">
                                    <img src="@/assets/icon/Ok.png" style="width: 20px;height: auto;" alt="">
                                    Giá cao nhất
                                </span>
                                <span class="lower" v-else>
                                    <img src="@/assets/icon/close.png" style="width: 20px;height: auto;" alt="">
                                    Giá thấp hơn
                                </span>
                            </template>
                        </b-table>
                        <Pagination :page="pages" @set-page="setPage" v-if="pages"/>
                    </div>
                    <div class="card-member col-auto" v-if="asset.public_status">
                        <h6>Thành viên tham gia đấu giá</h6>
                        <p>Số thành viên tham gia : {{ getDataJoined.length }}</p>
                        <div class="user-join">
                            <div class="info-user v" v-for="user in getDataJoined">
                                <div class="d-flex flex-wrap item">
                                    <div class="user-code col-5" @click="showInfoUser(user.member_id, 'member')">{{ user.member_code }}</div>
                                    <div class="d-flex justify-content-between col-7 align-items-center">
                                        <div class="user-name">{{ user.member_name }}</div>
                                        <span :class="[user.is_online == 1 ? 'status--onl' : 'status--off', 'status']"></span>
                                    </div>
                                </div>
                            </div>
                            <p class="no-data" v-if="getDataJoined.length == 0">Chưa có thành viên tham gia</p>
                        </div>
                    </div>
                </div>
            </b-tab>
            <b-tab title="Lịch sử trả giá" v-if="!propisClient && asset.autherity_role != 2">
                <div class="text mt-2">
                    <p class="user_join">Số người tham gia đấu giá: {{ countMemberJoined }}</p>
                    <button class="d-flex" @click="handelLoad()"><div class="loader-notifi" v-if="loading"></div>
                        <img v-else src="@/assets/icon/Delivery Time.png" alt=""> Làm mới </button>
                </div>
                <b-table responsive="md" :fields="bid_history.fields" :items="all_my_bid" tbody-tr-class="my-2">
                    <template #cell(index)="data" :class="{ 'return_price': row.item.withdraw_status == 1 }">
                        {{ data.index + 1 }}
                    </template>
                    <template #cell(price)="row">
                        <span :class="{ 'return_price': row.item.withdraw_status == 1 }" v-if="row.item.price == null">
                            0 VNĐ
                        </span>
                        <span :class="{ 'return_price': row.item.withdraw_status == 1 }" v-else>
                            {{ parseInt(row.item.price).toLocaleString("vi-VN") }} VNĐ
                        </span> <br>
                        <span v-show=" row.item.withdraw_status == 1" class="text-red">
                            Rút giá
                        </span>
                    </template>
                    <template #cell(time)="row" :class="{ 'return_price': row.item.withdraw_status == 1 }">
                        {{ formateTime(row.item.time) }}
                    </template>
                    <template #cell(status)="row">
                        <span class="highest"
                            v-if="row.item.price == highest_price && row.item.withdraw_status == 0">
                            <img src="@/assets/icon/Ok.png" style="width: 20px;height: auto;" alt="">
                            Giá cao nhất
                        </span>
                        <span class="lower" v-else :class="{ 'return_price': row.item.withdraw_status == 1 }">
                            <img src="@/assets/icon/close.png" style="width: 20px;height: auto;" alt="">
                            Giá thấp hơn
                        </span>
                    </template>
                </b-table>
                <Pagination :page="pages" @set-page="setPage" v-if="pages"/>
            </b-tab>
            <!-- <b-tab title="Thông tin tài sản" v-if="asset.asset_status == 1 || asset.public_status">
                <p>
                <div class="more-info">
                    <h5 class="more-info__ttl">Tài liệu liên quan</h5>
                    {{ asset }}
                    <ul class="list-info">
                        <li v-for="file in asset.required_file"><b-link href="">{{file.required_file_name }}</b-link></li>
                    </ul>
                </div>
                </p>
            </b-tab> -->
            <!-- <b-tab title="Tài sản tương tự" >
                <SameProduct />
            </b-tab> -->
        </b-tabs>
        
    </div>
</template>

<script>
import Pagination from "@/pages/web-view/components/paginate.vue";
import { state } from "@/service/socket";

export default {
    props: ['asset', 'top5AllBid', 'countMemberJoined', 'all_my_bid', 'highest_price', 'propisClient', 'dataClient', 'pages', 'all_bid', 'dataHighest'],
    components: {
        Pagination
    },
    data() {
        return {
            key: localStorage.getItem('member_key'),
            token: JSON.parse(localStorage.getItem('userInfo')).accessToken,
            bid_list: {
                fields: [
                    { key: 'index', label: 'Stt', thClass: 'text-center col-md-1', tdClass: 'text-center col-md-1' },
                    { key: 'member_code', label: 'Mã khách hàng', thClass: 'text-center col-md-3', tdClass: 'text-center col-md-3' },
                    { key: 'price', label: 'Giá đã trả (VNĐ)', thClass: 'text-center col-md-3', tdClass: 'text-center col-md-3 text-red' },
                    { key: 'time', label: 'Thời gian trả giá', thClass: 'text-center col-md-3', tdClass: 'text-center col-md-3' },
                    { key: 'status', label: 'Trạng thái', thClass: 'text-center col-md-2', tdClass: 'text-center col-md-2' },
                ],
                items: [
                    
                ],
            },
            bid_history: {
                fields: [
                    { key: 'index', label: 'Stt', thClass: 'text-center col-md-1', tdClass: 'text-center col-md-1' },
                    { key: 'price', label: 'Giá đã trả (VNĐ)', thClass: 'text-center col-md-3', tdClass: 'text-center col-md-3 text-red' },
                    { key: 'time', label: 'Thời gian trả giá', thClass: 'text-center col-md-3', tdClass: 'text-center col-md-3' },
                    { key: 'status', label: 'Trạng thái', thClass: 'text-center col-md-2', tdClass: 'text-center col-md-2' },
                ],
                items: [
                    
                ],
            },
            list_hightest:[],
            my_hightest:[],
            loading:false
        }
    },
    computed:{
        getDataJoined() {
            return state.dataJoined
        },
        
    },
    beforeUpdate(){
        this.bid_history.items = this.all_my_bid
        this.bid_list.items = this.top5AllBid
        this.publicStatus()
        if (this.asset.public_status == 1) {
            this.bid_list.items = this.all_bid
        }
    },
    mounted() {
        this.publicStatus()
        if (this.asset.public_status == 1 ) {
            this.bid_list.items = this.all_bid
        }
        else{
            this.bid_list.items = this.top5AllBid
        }
        this.bid_history.items = this.all_my_bid
        this.getHightest()
    },
    methods: {
        formateTime(time) {
            return this.$moment.utc(time).format("HH:mm:ss:SSS DD/MM/YYYY")
        },
        getHightest() {
            setInterval(() => {
                this.list_hightest = []
                let list_my_hightest = []
                this.all_bid.forEach(e => {
                    if (e.withdraw_status == 0 && e.price == this.highest_price) {
                        this.list_hightest.push(e)
                    }
                });
                this.all_my_bid.forEach(e => {
                    if (e.withdraw_status == 0) {
                        list_my_hightest.push(e)
                    }
                });
                this.my_hightest = list_my_hightest[0]
            }, 20);
        },
        handelLoad(){
            this.loading = true
            setTimeout(() => {
                this.loading = false
            }, 2000);
        },
        setPage(val) {
            this.$emit("setPage", val);
        },
        publicStatus(){
            if(this.asset.public_status){
                this.bid_list.fields = [
                    { key: 'member_code', label: 'Mã khách hàng', thClass: 'text-center col-md-3 public d-none', tdClass: 'text-center col-md-3 ' },
                    { key: 'member_name', label: 'Họ và tên', thClass: 'text-center col-md-4 public d-none', tdClass: 'text-center col-md-4 ' },
                    { key: 'time', label: 'Thời gian trả giá', thClass: 'text-center col-md-2 public d-none', tdClass: 'text-center col-md-2 ' },
                    { key: 'price', label: 'Giá đã trả (VNĐ)', thClass: 'text-center col-md-3 public d-none', tdClass: 'text-center col-md-3 ' },
                ]
            }
        }
    }
}
</script>
<style lang="css">
.tab-product-detail .nav-tabs {
    border: none;
}
.tab-product-detail .loader-notifi{
    position: relative;
    width: 27px;
    height: 27px;
}
.tab-product-detail .nav-tabs .nav-link:hover,
.tab-product-detail .nav-tabs .nav-link.active {
    border-bottom: 3px solid #EE3439;
}

.tab-product-detail .nav-tabs .nav-link.active {
    border-bottom: 3px solid #EE3439;
}

.tab-product-detail .nav-tabs .nav-link.active:hover {
    border-bottom: 3px solid #EE3439;
}

.tab-product-detail .nav-tabs .nav-link {
    font-weight: 500;
    font-size: 20px;
    color: #515151 !important;
    line-height: 23.44px;
    border: none;
    font-family: Roboto;
}

.tab-product-detail .nav-tabs .nav-link:hover {
    border: none;
    color: #515151;
}
.tab-product-detail.public .bid-list thead tr{
    background-color: #ffffff;
    font-size: 15px;
    font-weight: unset;
    height: auto;
}
th.public{
    font-weight: 500;
}
.th .public{
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
}
.th{
    padding: 0 2rem;
}
.lists-bid{
    height: 310px;
    overflow: auto;
}
.lists-bid::-webkit-scrollbar, .user-join::-webkit-scrollbar{
    width: 1px;
}
.tab-product-detail.public .bid-list .table-responsive-md{
    padding: 0 2rem;
    
}
.tab-product-detail.public tbody tr{
    background-color: #f8f8f8;
}
</style>
<style lang="css" scoped>
.tab-product-detail .nav-tabs {
    border: none;
}
.status--onl {
    background-color: #00B569;
    width: 6.5px;
    height: 6.5px;
    border-radius: 12px;
}
.status--off {
    background-color: #EE3439;
    width: 6.5px;
    height: 6.5px;
    border-radius: 12px;
}
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link.active {
    border-bottom: 3px solid #EE3439;
}

.tab-product-detail .nav-tabs .nav-link.active {
    border-bottom: 3px solid #EE3439;
}

.tab-product-detail .nav-tabs .nav-link.active:hover {
    border-bottom: 3px solid #EE3439;
}

.tab-product-detail .nav-tabs .nav-link {
    font-weight: 500;
    font-size: 20px;
    color: #515151 !important;
    line-height: 23.44px;
    border: none;
    font-family: Roboto;
}

.tab-product-detail .nav-tabs .nav-link:hover {
    border: none;
    color: #515151;
}

.tab-product-detail .text {
    display: flex;
    justify-content: space-between;
    margin: 0 20px;
    align-items: center;
    margin-bottom: 20px;
}

.tab-product-detail .user_join {
    font-weight: 500;
    font-size: 15px;
    line-height: 27px;
    margin-bottom: 0;
}

.tab-product-detail .text button {
    background: none;
    border: none;
    outline: none;
    font-size: 17px;
    color: #EE3439;
    font-weight: 500;
}

.tab-product-detail .more-info__ttl {
    font-weight: 500;
    font-size: 22px;
    line-height: 39.6px;
    padding: 20px 0 0 15px;
}

.tab-product-detail .more-info {
    background: #E5E5E5;
    border-radius: 10px;
    height: 255px;
}


.tab-product-detail .list-info li {
    position: relative;
    margin-left: 66px;
    padding: 5px 0;
}

.tab-product-detail .list-info li::before {
    content: " ";
    background: url('@/assets/icon/Note.png');
    width: 25px;
    height: 25px;
    background-repeat: no-repeat;
    position: absolute;
    left: -33px;
    top: 3px;
}

.tab-product-detail .list-info li a {
    text-decoration: none;
    color: #515151;
    font-size: 18px;
}

.tab-product-detail .highest::before {
    background: url('@/assets/icon/Ok.png');
}

.return_price {
    color: #EE3439;
    text-decoration: line-through;
}
.user-join{
    height: 333px;
    overflow: auto;
}
.info-user .item{
    height: 55px;
    background-color: #F0F0F0;
    align-items: center;
    margin: 0.25rem 0;
    padding: 0 0.5rem;
    border-radius: 15px;
}
.highest-price {
    display: flex;
    justify-content: space-between;
    background: #E7E7E7;
    border-radius: 15px;
    padding: 14px;
    color: #515151;
    font-weight: 500;
    width: 100%;
    font-size: 16px;
}
.highest-price p {
    margin: 0;
}

p.ttl {
    color: #515151;
    font-size: 18px;
    font-weight: 500;
    margin: 0.5rem 0;
}
.tab-product-detail.public{
    margin-bottom: 2rem;
}
.tab-product-detail.public .tabs{
    width: 100%;
}
.card-member{
    border-radius: 15px;
    margin-top: 32px;
    box-shadow: 2px 2px 6px 0px #00000026;
    width: 411px;
    padding: 0.25rem 0.75rem;
}
.card-member h6{
    font-size: 18px;
    font-weight: 500;
    line-height: 32px;
    margin-bottom: 0;
}
.card-member p{
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 29px;
    margin-bottom: 0;
}

@media (max-width: 768px) {
    .tab-product-detail .nav-tabs .nav-link {
        font-size: 17px;
    }
}
</style>