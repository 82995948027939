<template>
    <div :class="{ 'overlay': isLoading }">
        <div class="wp-settings">
            <b-row>
                <h5 style="font-size: 22px; margin-bottom: 15px;">Quản lý hệ thống</h5>
                <b-col cols="lg-7">
                    <div class="contact-info">
                        <h5>Thông tin liên hệ doanh nghiệp</h5>
                        <b-form autocomplete="off">
                            <b-row class="align-items-center">
                                <b-col cols="md-4"><label for="name">Tên doanh nghiệp:</label></b-col>
                                <b-col cols="md-8"><b-form-input id="name" placeholder="Nhập tên đầy đủ của doanh nghiệp"
                                        v-for="(item, index) in data" :key="index" v-if="item.type == 'company_name'"
                                        v-model="item.value"></b-form-input></b-col>
                                <b-col cols="md-4"><label for="email">Email:</label></b-col>
                                <b-col cols="md-8"><b-form-input type="email" id="email"
                                        placeholder="Nhập email của doanh nghiệp" v-for="(item, index) in data" :key="index"
                                        v-if="item.type == 'email'" v-model="item.value"></b-form-input></b-col>
                                <b-col cols="md-4"><label for="phone">Số điện thoại:</label></b-col>
                                <b-col cols="md-8"><b-form-input id="phone"
                                        placeholder="Nhập số điện thoại chính của doanh nghiệp"
                                        v-for="(item, index) in data" :key="index" v-if="item.type == 'phone'"
                                        v-model="item.value"></b-form-input></b-col>
                                <b-col cols="md-4"><label for="tax_code">Mã số thuế doanh nghiệp:</label></b-col>
                                <b-col cols="md-8"><b-form-input id="tax_code"
                                        placeholder="Nhập mã số thuế của doanh nghiệp" v-for="(item, index) in data"
                                        :key="index" v-if="item.type == 'tax'" v-model="item.value"></b-form-input></b-col>
                                <b-col cols="md-4"><label for="res_code">Giấy đăng ký hoạt động:</label></b-col>
                                <b-col cols="md-8"><b-form-input id="res_code"
                                        placeholder="Nhập mã số đăng ký của doanh nghiệp" v-for="(item, index) in data"
                                        :key="index" v-if="item.type == 'cetificate'"
                                        v-model="item.value"></b-form-input></b-col>
                                <b-col cols="md-4"><label for="website">Website:</label></b-col>
                                <b-col cols="md-8"><b-form-input id="website"
                                        placeholder="Nhập website chính của doanh nghiệp" v-for="(item, index) in data"
                                        :key="index" v-if="item.type == 'website'"
                                        v-model="item.value"></b-form-input></b-col>
                                <b-col cols="md-4"><label for="represent">Đại diện:</label></b-col>
                                <b-col cols="md-8"><b-form-input id="represent" placeholder="Nhập tên - chức vụ"
                                        v-for="(item, index) in data" :key="index" v-if="item.type == 'represent'"
                                        v-model="item.value"></b-form-input></b-col>
                                <b-col cols="md-4"><label for="address">Địa chỉ văn phòng chính:</label></b-col>
                                <b-col cols="md-8"><b-form-input id="address"
                                        placeholder="Nhập địa chỉ chi tiết văn phòng chính của doanh nghiệp"
                                        v-for="(item, index) in data" :key="index" v-if="item.type == 'address'"
                                        v-model="item.value"></b-form-input></b-col>
                            </b-row>
                            <div class="text-right mt-3">
                                <button @click="updateInfo" v-if="intListRole.includes(45)">Xác nhận</button>
                            </div>
                        </b-form>
                    </div>
                </b-col>
                <b-col cols="lg-5">
                    <div class="about">
                        <h5>Giới thiệu chung</h5>
                        <b-form>
                            <ckeditor :editor="editor" :config="editorConfig" @ready="onReady" v-for="(item, index) in data"
                                :key="index" v-if="item.type == 'infomation'" v-model="item.value"></ckeditor>
                            <label for="chuc-danh">Chức danh</label>
                            <b-form-input id="chuc-danh" placeholder="Nhập chức danh" v-for="(item, index) in data"
                                :key="index" v-if="item.type == 'position'" v-model="item.value"></b-form-input>
                            <label for="person_name">Tên người đại diện</label>
                            <b-form-input id="person_name" placeholder="Nhập họ và tên" v-for="(item, index) in data"
                                :key="index" v-if="item.type == 'repre'" v-model="item.value"></b-form-input>
                            <div class="text-right mt-3">
                                <button @click="updateInfo" v-if="intListRole.includes(45)">Lưu giới thiệu</button>
                            </div>
                        </b-form>
                    </div>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="lg-12">
                    <div class="intro">
                        <h5>Giới thiệu công ty</h5>
                        <div class="area_about" v-for="(item, index) in data" :key="index"
                            v-if="item.type == 'company_introduction'" v-html="item.value"></div>
                        <div class="text-right">
                            <button class="btn-edit" @click="showFormEdit('company_introduction', data)"
                                v-if="intListRole.includes(45)">Chỉnh sửa</button>
                        </div>
                    </div>
                </b-col>
            </b-row>

            <b-row style="margin-top: 13px;">
                <h5>Thông tin chân trang</h5>
                <b-col cols="lg-6">
                    <div class="about-uss">
                        <h5>Về chúng tôi</h5>
                        <p @click="showFormEdit('operating_regulations', data)">Quy chế hoạt động</p>
                        <p @click="showFormEdit('dispute_settlement_mechanism', data)">Cơ chế giải quyết tranh chấp</p>
                        <p @click="showFormEdit('document_user_manual', data)">Hướng dẫn sử dụng tài liệu</p>
                        <p style="margin-bottom: 0;" @click="showFormEdit('auction_guide', data)">Hướng dẫn đấu giá</p>
                    </div>
                </b-col>

                <b-col cols="lg-6">
                    <div class="policy">
                        <h5>Chính sách</h5>
                        <p @click="showFormEdit('normative_documents', data)">Văn bản quy phạm</p>
                        <p @click="showFormEdit('auction_rules', data)">Nội quy đấu giá</p>
                        <!-- <p>Tin tức</p> -->
                        <p style="margin-bottom: 0;" @click="showFormEdit('privacy_policy', data)">Chính sách bảo mật thông
                            tin</p>
                    </div>
                </b-col>
            </b-row>

            <h5 style="margin-top: 11px;">Ứng dụng</h5>
            <div class="wp-footer">
                <b-row>
                    <b-col cols="lg-6">
                        <div class="text-center">
                            <img src="@/assets/images/google_app.png" alt="">
                        </div>
                        <label for="gg_link">Link tải ứng dụng:</label>
                        <b-form-input id="gg_link" placeholder="Nhập link" v-for="(item, index) in data" :key="index"
                            v-if="item.type == 'google_play'" v-model="item.url"></b-form-input>
                    </b-col>

                    <b-col cols="lg-6">
                        <div class="text-center">
                            <img src="@/assets/images/appstore.png" alt="">
                        </div>
                        <label for="app_link">Link tải ứng dụng:</label>
                        <b-form-input id="app_link" placeholder="Nhập link" v-for="(item, index) in data" :key="index"
                            v-if="item.type == 'app_store'" v-model="item.url"></b-form-input>
                    </b-col>
                    <div class="text-right mt-3">
                        <button @click="updateInfo" v-if="intListRole.includes(45)">Xác nhận</button>
                    </div>
                </b-row>
            </div>

            <h5 style="margin-top: 11px;">Đồng hồ đếm ngược</h5>
            <div class="wp-countdown">
                <p>Thời gian</p>
                <input type="text" placeholder="Nhập số phút" class="form-control"
                    onkeypress='return event.charCode >= 48 && event.charCode <= 57' v-for="(item, index) in data"
                    :key="index" v-if="item.type == 'start_time'" v-model="item.value">
                <div class="text-right mt-3">
                    <button @click="updateInfo" v-if="intListRole.includes(45)">Bắt đầu đếm giờ</button>
                </div>
            </div>
            <formSettings ref="formSettings" @handleCloseModal="listenDialog" />
        </div>
        <div class="loader" v-if="isLoading"></div>
    </div>
</template>
<script>
import Editor from '@ckeditor/ckeditor5-build-decoupled-document';
import '@ckeditor/ckeditor5-build-classic/build/translations/vi';
import axios from 'axios'
import formSettings from './modal/formEdit'
export default {
    components: {
        formSettings,
    },
    data() {
        function uploader(editor) {
            editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
                return uploadImage(loader);
            };
        }

        function uploadImage(loader) {
            return {
                upload: () => {
                    return new Promise((resolve, reject) => {
                        loader.file.then(async (file) => {
                            try {
                                if (!file) return

                                var data = {
                                    'file': file,
                                    'path': 'news/image/'
                                }

                                await axios.post(this.$axios + '/common/uploadFile', data, {
                                    headers: {
                                        'x-access-token': '',
                                        'Content-Type': 'multipart/form-data'
                                    }
                                }).then(res => {
                                    const url = this.$storage + res.data.list_result[0].path_tam
                                    resolve({ default: url })
                                })
                            } catch (error) {
                                reject(error)
                            }
                        })
                    })
                }
            }
        }
        return {
            token: '',
            data: [],
            date_start_time: '',
            time_start_time: '',
            date_end_time: '',
            time_end_time: '',
            listRole: [],
            intListRole: [],
            isLoading: false,
            editor: Editor,
            editorConfig: {
                extraPlugins: [uploader],
                language: 'vi',
                toolbar: {
                    items: [
                        'undo',
                        'redo',
                        '|',
                        'heading',
                        '|',
                        'bold',
                        'italic',
                        'strikethrough',
                        'underline',
                        '|',
                        'bulletedList',
                        'numberedList',
                        '|',
                        'outdent',
                        'indent',
                        '|',
                        'imageUpload',
                        'link',
                        '|',
                        'blockQuote',
                        'insertTable',
                        'alignment',
                        '|',
                        'fontFamily',
                        'fontSize',
                        'fontColor',
                        'fontBackgroundColor',
                    ]
                },
            }
        }
    },
    mounted() {
        const storedData = localStorage.getItem('admInfo')
        if (storedData) {
            var data = JSON.parse(storedData)
            this.token = data.accessToken
            data.roles.arr_resource.forEach(element => {
                this.listRole.push(element.resource_id)
            });
            this.intListRole = this.listRole.map(Number)
        }
        this.getInfo()
    },
    methods: {
        showFormEdit(type, data) {
            this.$refs.formSettings.show(type, data)
        },
        listenDialog(isClose = false) {
            if (!isClose) {
                this.getInfo()
            }
        },
        async getInfo() {
            await this.$axios.get('/common/getInfomation', '', {
                headers: {
                    'x-access-token': ''
                }
            }).then(res => {
                this.data = res.data
                res.data.forEach(ele => {
                    if (ele.type == 'start_time') {
                        this.date_start_time = this.$moment.utc(ele.value).format("YYYY-MM-DD")
                        this.time_start_time = this.$moment.utc(ele.value).format("HH:mm:ss")
                    } else if (ele.type == 'end_time') {
                        this.date_end_time = this.$moment.utc(ele.value).format("YYYY-MM-DD")
                        this.time_end_time = this.$moment.utc(ele.value).format("HH:mm:ss")
                    }
                    if (ele.type == 'infomation') {
                        this.editor.clipboard.dangerouslyPasteHTML(ele.value);
                    }
                });
            }).catch(err => {
                if (err.status == 409) {
                    localStorage.removeItem('admInfo')
                    this.$toast.error('Tài khoản được đăng nhập từ thiết bị khác!')
                    this.$router.push('/loginAdmin')
                } else if (err.status == 401) {
                    localStorage.removeItem('admInfo')
                    this.$toast.error('Phiên đăng nhập đã hết hạn!')
                    this.$router.push('/loginAdmin')
                }
            })
        },
        async updateInfo() {
            this.isLoading = true
            event.preventDefault()
            // this.data.forEach(ele => {
            //     if(ele.type == 'infomation') {
            //         ele.value = this.editor.root.innerHTML
            //     }
            // });
            const data = {
                data: this.data
            }
            await this.$axios.put('/common/updateInfomation', data, {
                headers: {
                    'x-access-token': this.token
                }
            }).then(res => {
                this.$toast.success('Lưu thông tin thành công!')
                this.getInfo()
                this.isLoading = false
            }).catch(err => {
                this.$toast.error('Lưu thông tin không thành công!')
                this.isLoading = false
            })
        },
        onReady(editor) {
            editor.ui.getEditableElement().parentElement.insertBefore(
                editor.ui.view.toolbar.element,
                editor.ui.getEditableElement()
            );
        },
    }
}
</script>
<style lang="css" scoped>
.wp-settings {
    color: #515151;
    font-family: Roboto;
}

h5 {
    font-size: 20px;
    font-weight: 700;
}

.contact-info {
    background-color: #FFF;
    border-radius: 10px;
    padding: 10px 20px 10px 12px;
    height: 100%;
}

.contact-info input,
.about input {
    background-color: #EEE;
    border-radius: 15px;
    border: none;
    margin-bottom: 8px;
    padding-top: 12px;
    padding-bottom: 12px;
}

input:focus {
    box-shadow: none;
}

input::placeholder {
    color: #B4B4B4;
}

.btn_add_agency {
    background: none;
    border: none;
    color: #EE3439;
    font-weight: 500;
    margin-top: 10px;
    width: unset;
}

.about {
    background-color: #FFF;
    padding: 10px 8px 16px 9px;
    border-radius: 10px;
    height: 100%;
}

.about input {
    padding-top: 8px;
    padding-bottom: 8px;
}

.about label {
    color: #515151;
    font-weight: 500;
    margin-top: 10px;
}

button {
    background-color: #EE3439;
    border: none;
    color: #FFF;
    border-radius: 20px;
    font-weight: 500;
    width: 140px;
    height: 30px;
    font-size: 14px;
}

button.btn-cancel,
.btn-edit {
    background-color: #FFF;
    color: #EE3439;
    border: 1px solid #EE3439;
    margin-right: 10px;
}

.intro {
    margin-top: 20px;
    padding: 7px 15px 11px 15px;
    background-color: #FFF;
    border-radius: 10px;
}

.intro .area_about {
    border-radius: 10px;
    background: #F0F0F0;
    min-height: 120px;
    padding: 10px 45px;
    max-height: 320px;
    overflow-y: auto;
    margin-bottom: 11px;
}

.intro .area_about::-webkit-scrollbar {
    width: 0;
}

.about-uss,
.policy {
    background-color: #FFF;
    border-radius: 10px;
    padding: 9px 20px 17px 20px;
}

.about-uss p,
.policy p {
    position: relative;
    display: flex;
    align-items: center;
    background-color: #F7F7F7;
    border-radius: 20px;
    padding: 13px 9px 13px 18px;
    cursor: pointer;
}

.about-uss p::after,
.policy p::after {
    position: absolute;
    content: '';
    background-image: url('@/assets/admin/icon/Expand\ Arrow.png');
    background-repeat: no-repeat;
    width: 51px;
    height: 30px;
    right: 0;
    transform: rotate(-90deg);
    cursor: pointer;
}

.wp-footer {
    background-color: #FFF;
    border-radius: 10px;
    padding: 38px 17px 20px 30px;
}

.wp-footer label {
    font-weight: 500;
}

.wp-footer input {
    background-color: #F7F7F7;
    border-radius: 20px;
    border: none;
    padding-top: 15px;
    padding-bottom: 15px;
}

.wp-footer input:focus {
    box-shadow: none;
}

.wp-footer input::placeholder {
    color: #B4B4B4;
    font-weight: 500;
}

.wp-countdown {
    border-radius: 10px;
    background: #FFF;
    padding: 39px 17px 23px 30px;
}

.wp-countdown p {
    font-weight: 500;
    color: #515151;
}

.form-control:focus {
    border-color: #ced4da;
}
</style>

<style>
.wp-settings .ql-container {
    min-height: 250px;
}

.wp-countdown button.h-auto {
    opacity: 0;
}

.wp-countdown .no-gutters {
    margin: 0;
}

.wp-countdown .b-calendar-grid-help {
    display: none;
}

.wp-countdown .b-calendar .form-control:focus,
.wp-countdown .b-form-datepicker.form-control.focus,
.wp-countdown .form-control.focus {
    box-shadow: none;
    border: 1px solid #ced4da6e;
}

.wp-countdown .text-truncate {
    padding: 0;
}

.wp-countdown .b-form-datepicker,
.wp-countdown .b-form-timepicker {
    background-color: #EEEEEE;
    border-radius: 12px;
    padding: 2px 12px;
}

.wp-countdown .text-muted {
    color: #B4B4B4 !important;
}</style>