<template>
    <div class="d-flex justify-content-between header-admin">
        <b-link :to="routeLink" class="d-flex logo align-items-center">
            <img src="@/assets/admin/images/logo-admin.png" alt="" class="logo__ico">
            <p class="logo__ttl">DGV AUCTION<br/> PARTNERSHIP COMPANY</p>
        </b-link>
        <div class="d-flex align-items-center">
            <b-nav-item style="list-style-type: none;" class="notify">
                <span class="count-noti" v-if="listNotification.count_new_noti !==''">{{ formartPreCountNoti(listNotification.count_new_noti) }}</span>
                <b-dropdown class="notificate" right >
                    <div class="drodown-content" >
                        <div class="col-12 header">
                            Thông báo
                        </div>
                        <div class="col-12 p-2 body" @scrollend="loadData()">
                            <div v-for="(notificate, index) in listNotification.result" @mousedown="viewNotificate(notificate)" :class="{ 'viewed': !notificate.view_status }">
                                <div class="col-12 p-2 content" v-if="notificate.type == 5">
                                    <h6>{{ notificate.type_name }} </h6>
                                    <p>{{ notificate.content }}
                                        <p style="font-size: 13px; color: #515151;">{{ notificate.notification }}</p>
                                    </p>
                                    <div class="button text-right mt-3">
                                        <button class="btn btn-outline" @mousedown="openNewTab('/admin/list-auction/detail-asset/' + ecnode(notificate.asset_id))">Xem chi tiết</button>
                                    </div>
                                </div>
                                <div class="col-12 p-2 content" v-if="notificate.type == 7">
                                    <h6>{{ notificate.type_name }} </h6>
                                    <p>{{ notificate.content }}
                                        <p style="font-size: 13px; color: #515151;">{{ notificate.notification }}</p>
                                    </p>
                                    <div class="button text-right mt-3">
                                        <button class="btn btn-outline" @mousedown="openNewTab('/admin/list-customer-new')">Xem chi tiết</button>
                                    </div>
                                </div>
                                <div class="col-12 p-2 content" v-if="notificate.type == 8">
                                    <h6>{{ notificate.type_name }} </h6>
                                    <p>{{ notificate.content }}
                                        <p style="font-size: 13px; color: #515151;">{{ notificate.notification }}</p>
                                    </p>
                                    <div class="button text-right mt-3">
                                        <button class="btn btn-outline" @mousedown="openNewTab('/admin/approve-asset/detail-asset/' + encode(notificate.asset_id))">Xem chi tiết</button>
                                    </div>
                                </div>
                                <div class="col-12 p-2 content" v-if="notificate.type == 9">
                                    <h6>{{ notificate.type_name }} </h6>
                                    <p>{{ notificate.content }}
                                        <p style="font-size: 13px; color: #515151;">{{ notificate.notification }}</p>
                                    </p>
                                    <div class="button text-right mt-3">
                                        <button class="btn btn-outline" @mousedown="openNewTab('/admin/approve-auction')">Xem chi tiết</button>
                                    </div>
                                </div>
                                <div class="col-12 p-2 content" v-if="notificate.type == 10">
                                    <h6>{{ notificate.type_name }} </h6>
                                    <p>{{ notificate.content }}
                                        <p style="font-size: 13px; color: #515151;">{{ notificate.notification }}</p>
                                    </p>
                                    <div class="button text-right mt-3">
                                        <button class="btn btn-outline" @mousedown="openNewTab('/admin/app-joined/' + encode(notificate.asset_id))">Xem chi tiết</button>
                                    </div>
                                </div>
                                <div class="col-12 p-2 content d-flex"  v-if="notificate.type == 11">
                                    <div>
                                        <h6>{{ notificate.notification }} </h6>
                                        <p>Công ty đấu giá hợp danh DGV thông báo: {{ notificate.notification }} </p>
                                        <div class="button text-right mt-3">
                                            <button class="btn btn-outline" @mousedown="openNewTab('/admin/detail-news/' + encode(notificate.news_id))">Xem chi tiết</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 p-2 content" v-if="notificate.type == 14">
                                    <h6>{{ notificate.type_name }} </h6>
                                    <p>{{ notificate.content }}
                                        <p style="font-size: 13px; color: #515151;">{{ notificate.notification }}</p>
                                    </p>
                                    <div class="button text-right mt-3">
                                        <button class="btn btn-outline" @mousedown="openNewTab('/auctioneer/auction/list-asset/' + encode(notificate.auction_id))">Xem chi tiết</button>
                                    </div>
                                </div>
                                <div class="col-12 p-2 content" v-if="notificate.type == 15">
                                    <h6>{{ notificate.type_name }} </h6>
                                    <p>{{ notificate.content }}
                                        <p style="font-size: 13px; color: #515151;">{{ notificate.notification }}</p>
                                    </p>
                                    <div class="button text-right mt-3">
                                        <button class="btn btn-outline" @mousedown="openNewTab('/auctioneer/list-approve/' + encode(notificate.asset_id))">Xem chi tiết</button>
                                    </div>
                                </div>
                                <div class="col-12 p-2 content" v-if="notificate.type == 16 || notificate.type == 17">
                                    <h6>{{ notificate.type_name }} </h6>
                                    <p>{{ notificate.content }}
                                        <p style="font-size: 13px; color: #515151;">{{ notificate.notification }}</p>
                                    </p>
                                </div>
                                <div class="col-12 p-2 content" v-if="notificate.type == 18">
                                    <h6>{{ notificate.type_name }} </h6>
                                    <p>{{ notificate.content }}
                                        <p style="font-size: 13px; color: #515151;">{{ notificate.notification }}</p>
                                    </p>
                                    <div class="button text-right mt-3">
                                        <button class="btn btn-outline" @mousedown="openNewTab('/auctioneer/list-authen/' + encode(notificate.auction_id))">Xem chi tiết</button>
                                    </div>
                                </div>
                            </div>
                            <div class="loader-notifi" v-if="listNotification.pagination && listNotification.pagination.per_page <= listNotification.pagination.total_element"></div>
                            <div v-else class="text-center" style="font-size: 14px; color: #EE3439; font-weight: 500;">Đã hết thông báo</div>
                        </div>
                    </div>
                </b-dropdown>
            </b-nav-item>

            <b-dropdown class="menu-page" right>
                <template #button-content>
                    <img v-if="avatar !== null" :src="$storage + avatar" alt="" class="avatar" @error="handleError">
                    <img v-else src="@/assets/admin/images/adm_avatar.png" alt="" class="avatar">
                    <div class="user_name">
                        <p>{{ full_name }}</p>
                        <p>{{ role_name }}</p>
                    </div>
                </template>
                <b-dropdown-item>
                    <img v-if="this.avatar !== null" :src="this.$storage + avatar" alt="" class="avatar" @error="handleError">
                    <img v-else src="@/assets/admin/images/adm_avatar.png" alt="" class="avatar">
                    <div>
                        <p>{{ this.full_name }}</p>
                        <p style="font-weight: 400;">{{ this.email }}</p>
                    </div>
                </b-dropdown-item>
                <b-dropdown-item>
                    <img src="@/assets/admin/icon/Customer1.png" alt="">
                    <p class="pl-2" @click="$router.push(routeLink + '/page')">Trang cá nhân</p>
                </b-dropdown-item>
                <b-dropdown-item>
                    <img src="@/assets/admin/icon/Logout.png" alt="">
                    <p class="pl-2" @click="logoutAdmin" v-if="$route.path.search('admin') == 1">Đăng xuất</p>
                    <p class="pl-2" @click="logout" v-else>Đăng xuất</p>
                </b-dropdown-item>
            </b-dropdown>
        </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
import { notification } from '@/service/fireBase'
export default {
    data(){
        return{
            routeLink: '',
            role_name: '',
            full_name: '',
            email: '',
            avatar: '',
            token: '',
            showListNoti: false,
            listNotification:{},
            paramsNoitifi:{
                page: 1,
                per_page: 5,
            },
        }
    },
    computed: {
        getDeviceToken() {
            return notification
        },
    },
    mounted() {
        const currentPath = this.$route.path;
        if (currentPath.search('auctioneer') == true) {
            this.routeLink = '/auctioneer'
            const storedData = localStorage.getItem('auInfo')
            if(storedData) {
                var data = JSON.parse(storedData)
                this.role_name = data.roles.role_name
                this.full_name = data.full_name
                this.email = data.email
                this.avatar = data.avatar
                this.token = data.accessToken
            }
            this.getListNotification(this.paramsNoitifi, 'DGV')
        } else {
            this.routeLink = '/admin'
            const storedData = localStorage.getItem('admInfo')
            if(storedData) {
                var data = JSON.parse(storedData)
                this.role_name = data.roles.role_name
                this.full_name = data.full_name
                this.email = data.email
                this.avatar = data.avatar
                this.token = data.accessToken
            }
            this.getListNotification(this.paramsNoitifi, 'Admin')
        }
        setTimeout(() => {
            this.showNotification()
        }, 200);
    },
    methods: {
        openNewTab(url) {
            window.open(url, '_blank');
        },
        ...mapActions(['logoutAu', 'logoutAdm']),
        async logout() {
            await this.$axios.post('/auth/signout','', {
                headers: {
                    'x-access-token': this.token
                } 
            }).then(res => {
                this.logoutAu();
                this.$router.push('/loginAuctioneer')
            })
        },
        async logoutAdmin() {
            await this.$axios.post('/auth/signout','', {
                headers: {
                    'x-access-token': this.token
                } 
            }).then(res => {
                this.logoutAdm();
                this.$router.push('/loginAdmin')
            })
        },
        formartPreCountNoti(count){
            if (count > 9) {
                return '9+'
            }else{
                return count
            }
        },
        getListNotification(params, type) {
            if (type == 'DGV') {
                this.$axios.get('/common/getListNotificationForDGV', {
                    params: params,
                    headers: {
                        'x-access-token': this.token
                    }
                }).then(res => {
                    this.listNotification = res.data
                }).catch(err => {
                    if (err.response.status == 409) {
                        localStorage.removeItem('auInfo')
                        this.$toast.error('Tài khoản được đăng nhập từ thiết bị khác!')
                        this.$router.push('/loginAuctioneer')
                    } else if (err.response.status == 401) {
                        localStorage.removeItem('auInfo')
                        this.$toast.error('Phiên đăng nhập đã hết hạn!')
                        this.$router.push('/loginAuctioneer')
                    }
                })
            } else {
                this.$axios.get('/common/getListNotificationForAdmin', {
                    params: params,
                    headers: {
                        'x-access-token': this.token
                    }
                }).then(res => {
                    this.listNotification = res.data
                }).catch(err => {
                    if (err.response.status == 409) {
                        localStorage.removeItem('admInfo')
                        this.$toast.error('Tài khoản được đăng nhập từ thiết bị khác!')
                        this.$router.push('/loginAdmin')
                    } else if (err.response.status == 401) {
                        localStorage.removeItem('admInfo')
                        this.$toast.error('Phiên đăng nhập đã hết hạn!')
                        this.$router.push('/loginAdmin')
                    }
                })
            }
        },
        showNotification(){
            let mouse = this
            var noticeMe = this.getDeviceToken.notificate
            if (noticeMe) {
                noticeMe.onMessageHandler = function (payload) {
                    const notificationOptions = {
                        body: payload.notification.body,
                        icon: '/dgv_logo.png'
                    };
                    new Notification(payload.notification.title, notificationOptions)
                    const currentPath = mouse.$route.path;
                    if (payload.data.logout_status == 1 && payload.data.role == 2 && currentPath.search('auctioneer') == true) {
                        mouse.logout()
                    } else if(payload.data.logout_status == 1 && payload.data.role == 1 && currentPath.search('admin') == true) {
                        mouse.logoutAdmin()
                    }
                    else {
                        const currentPath = mouse.$route.path;
                        if (currentPath.search('auctioneer') == true) {
                            mouse.getListNotification(mouse.paramsNoitifi, 'DGV')
                        } else {
                            mouse.getListNotification(mouse.paramsNoitifi, 'Admin')
                        }
                    }
                }
            }
        },
        loadData(){
            this.paramsNoitifi.per_page += 5;
            const currentPath = this.$route.path;
            if (currentPath.search('auctioneer') == true) {
                this.getListNotification(this.paramsNoitifi, 'DGV')
            } else {
                this.getListNotification(this.paramsNoitifi, 'Admin')
            }
        },
        async viewNotificate(notificate){
            if (!notificate.view_status ) {
                await this.$axios.post('/common/viewNotification', {
                    notification_id: notificate.id,
                }, {
                    headers: {
                        'x-access-token': this.token,
                    }
                }).then((res) => {
                    const currentPath = this.$route.path;
                    if (currentPath.search('auctioneer') == true) {
                        this.getListNotification(this.paramsNoitifi, 'DGV')
                    } else {
                        this.getListNotification(this.paramsNoitifi, 'Admin')
                    }
                }).catch((err) => {
                    this.checkAuthenticate(err)
                })
            }
        }
    }
}
</script>
<style lang="css" scoped>
    .logo__ico {
        width: 54px;
        height: 52px;
    }

    .logo__ttl {
        font-size: 14.84px;
        font-family: Libre Bodoni;
        color: #DEDEDE;
        padding-left: 10px;
    }

    .notify {
        padding-right: 15px;
        cursor: pointer;
    }

    .user_name {
        min-width: 150px;
        color: #FFFFFF;
        font-weight: 500;
    }
    .user_name p:last-child {
        font-weight: 400;
    }
    .avatar {
        width: 45px;
        height: 45px;
        border-radius: 31px;
        margin-right: 12px;
        object-fit: cover;
    }

    .header-admin {
        background-color: #080808;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 500;
        color: #FFFFFF;
        height: 70px;
        align-items: center;
        padding-left: 25px;
        padding-right: 6%;
    }

    .header-admin p {
        margin: 0;
    }
    
    .count-noti{
        position: absolute;
        background-color: #FBE3B0;
        z-index: 1000;
        color: #515151;
        font-size: 15px;
        border-radius: 20px;
        height: 17px;
        width: 18px;
        font-size: 11.33px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 0 0 18px;
        padding: 0 2px;
        font-weight: 500;
        top: 7px;
    }

    .notificate .viewed .content{
        background-color: #FFd8d8;
    }

    @media (max-width: 768px) {
         .logo__ttl {
            display: none;
        }
    }
    @media (max-width: 425px) {
         .user_name {
            display: none;
        }
    }
</style>

<style>
    .header-admin .notificate button.dropdown-toggle {
        background-image: url('@/assets/icon/Alarm.png');
        background-color: #00000000;
        border: unset;
        background-repeat: no-repeat;
    }
    .header-admin .notificate .dropdown-menu.show {
        width: 392px;
        top: 10px !important;
    }

    .header-admin .notificate ul.dropdown-menu {
        background-color: #00000000;
        border: unset;
    }

    .header-admin .notificate .header {
        font-family: Roboto;
        font-size: 20px;
        font-weight: 600;
        color: #515151;
        padding: 1rem 1rem 0 1rem;
    }

    .header-admin .notificate .footer {
        background-color: #00000000;
        font-family: Roboto;
        color: #EE3439;
        font-weight: 500;
        font-size: 16px;
        padding: 0.5rem;
    }

    .header-admin .notificate .body {
        height: 500px;
        overflow: auto;
    }

    .header-admin .notificate .body::-webkit-scrollbar {
        width: 0;
    }

    .header-admin .notificate .content {
        background-color: #EEEEEE;
        border-radius: 10px;
        margin: 0.5rem 0;
    }

    .header-admin .notificate .drodown-content {
        background-color: #ffffff;
        border-radius: 20px;
        overflow: hidden;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
        padding-bottom: 10px;
    }

    .header-admin .button .btn {
        border: #515151 1px solid;
        font-weight: 500;
        font-size: 14px;
        color: #515151;
        width: 136px;
        height: 30px;
        border-radius: 30px;
        padding: 0;
    }

    .header-admin .menu-page {
        /* width: 270px; */
    }

    .header-admin .menu-page:focus-visible,
    .header-admin .notificate  {
        box-shadow: none;
        outline: none;
    }

    .header-admin .notificate button:focus-visible {
        box-shadow: none;
    }

    .header-admin .menu-page button {
        background: none;
        border: none;
        display: flex;
        text-align: left;
        border-left: 1px solid #FFFFFF;
        border-radius: 0;
    }

    .header-admin .menu-page button:active,
    .header-admin .menu-page button:focus-visible {
        background: none;
        box-shadow: none;
        border-color: #FFF;
    }

    .header-admin .menu-page button::after {
        display: none;
    }

    .header-admin .menu-page ul {
        width: 100%;
        box-shadow: 0px 0px 12px 0px #0000000D;
        border-radius: 10px;
        border: none;
        padding: 0 11px;
        min-width: max-content;
        width: 100%;
    }

    .header-admin .menu-page .dropdown-item {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #D8D8D8;
        font-weight: 500;
        padding: 12px 0;
        color: #515151;
    }

    .header-admin .menu-page li:last-child .dropdown-item {
        border: none;
        color: #EE3439;
    }

    .header-admin .menu-page .dropdown-item:hover {
        background: none;
    }
</style>
