<template>
    <div class="auctioneer_screen">
        <div class="row" style="margin: 0;">
            <b-row class="auctioneer_screen__ttl">
                <b-col cols="lg-9">
                    <h5>Cuộc đấu giá: {{ title }}</h5>
                </b-col>
                <!-- <b-col cols="lg-3" class="title-time text-center">
                    <p>{{ currentTime }}</p>
                    <p>{{ this.$moment().format('dddd') }} - {{ this.$moment().format('l') }}</p>
                </b-col> -->
            </b-row>
            <b-row class="mb-3">
                <b-col cols="lg-3"></b-col>
                <b-col cols="lg-6">
                    <p class="status-ttl" v-if="this.status_room == 0">Điểm danh đấu giá</p>
                    <p class="status-ttl" v-else>Đấu giá</p>
                </b-col>
                <b-col cols="lg-3" class="text-right" v-if="this.status_room == 1">
                    <button class="stop-auction" @click="handleKick('stop', '', '', auction_key)"> Dừng cuộc đấu giá</button>
                </b-col>
            </b-row>
            <div class="col-xl-3" style="padding: 0;">
                <InfoProduct @sendDataTitle="getDataTitle" />
            </div>
            <div class="col-xl-6 tab-user">
                <TableUser />
            </div>
            <div class="col-xl-3 user">
                <User />
            </div>
        </div>
        <modalKick ref="modalKick"  @destroyRoom="destroyRoom" />
        <modalAccept />
        <modalApprove />
        <b-modal id="" ref="complain" centered hide-footer hide-header content-class="modal-width" modal-class="complain">
            <div class="complain text-center" style="color: #444;">
                <img src="@/assets/admin/icon/warning.png" alt="">
                <h5 style="font-weight: 500;">Yêu cầu khiếu nại</h5>
                <p>Khách mời tham gia đấu giá gửi yêu cầu khiếu nại cuộc đấu giá</p>
                <p style="text-align: left;">Người gửi khiếu nại: <span style="font-weight: 500;">{{ getDataComplain.client_role }}</span></p>
                <p style="text-align: left;">Lý do khiếu nại: <span style="font-weight: 500;">{{ getDataComplain.complain }}</span></p>
                <b-button class="mt-3" block @click="$refs.complain.hide()">Xác nhận</b-button>
            </div>
        </b-modal>
    </div>
</template>
<script>
import InfoProduct from './components/info-product.vue'
import TableUser from './components/table-user.vue'
import User from './components/user.vue'
import modalKick from './modal/kick-user.vue';
import modalAccept from './modal/accept.vue';
import modalApprove from './modal/approve.vue';
import { state } from "@/service/socket";
export default {
    components: {
        InfoProduct,
        User,
        TableUser,
        modalKick,
        modalAccept,
        modalApprove
    },
    data() {
        return {
            currentTime: '',
            dataSocketRegister: [],
            dataSocketJoined: [],
            title: '',
            auction_key: '',
            asset_id: '',
            auction_id: '',
            status_room: '',
            socket: null
        }
    },
    computed: {
        countdown() {
            return this.timeLeft ? this.formatTime(this.timeLeft, 'hour') : '00 giờ      00 phút      00 giây';
        },
        getDataComplain() {
            if(state.dataComplain.length !== 0) {
                this.$nextTick(() => {
                    this.$refs['complain'].show()
                })
            }
            return state.dataComplain
        },
    },
    methods: {
        handleKick(code, id, socket_id, auction_key) {
            this.$refs.modalKick.show(code, id, socket_id, auction_key)
        },
        getDataTitle(data) {
            this.title = data.title
            this.auction_key = data.auction_key
            this.asset_id = data.asset_id
            this.auction_id = data.auction_id
            this.connectDGV()
            const key = '';
            const token = JSON.parse(localStorage.getItem('auInfo')).accessToken;
            this.socket = this.$socket(data.auction_key, key, token)
            this.socket.connect()
            this.socket.on('room_status', (res) => {
                this.status_room = res.room_status
            })
        },
        destroyRoom(note) {
            this.socket.emit("destroyRoom", {note: note})
        },
        async connectDGV() {
            const data = {
                asset_id: this.asset_id,
                auction_id: this.auction_id,
            }
            await this.$axios.post('/auction/dgvConnect', data, {
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('auInfo')).accessToken
                }
            })
        },
        async disconnectDGV() {
            const data = {
                asset_id: this.asset_id,
                auction_id: this.auction_id,
            }
            await this.$axios.post('/auction/dgvDisconnect', data, {
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('auInfo')).accessToken
                }
            })
        }
    },
    created() {
        setInterval(() => {
            this.currentTime = this.$moment().format('LTS');
        });
    },
    beforeDestroy() {
        this.disconnectDGV()
    }
}
</script>
<style lang="css" scoped>
.auctioneer_screen {
    background-color: #EDEDED;
    font-family: Roboto;
    color: #515151;
    padding-bottom: 95px;
}

.auctioneer_screen__ttl {
    color: #515151;
    font-size: 20px;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
    font-family: Libre Bodoni;
    border-bottom: 1px solid #e1e1e1;
}

.title-time {
    width: max-content;
}
.title-time p {
    margin: 0;
    color: #DB282C;
}

.title-time p:first-child {
    font-size: 24px;
    font-weight: 700;
}

.title-time p:last-child {
    font-size: 14px;
    font-weight: 500;
    font-family: Roboto;
    text-transform: capitalize;
}

.status-ttl {
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    color: #515151;
    font-family: Libre Bodoni;
    margin: 0;
}

.auctioneer_screen .stop-auction {
    color: #EE3439;
    font-size: 15px;
    font-weight: 500;
    border: 1px solid #EE3439;
    border-radius: 15px;
    display: inline-block;
    max-height: 32px;
    padding: 4px 26px;
}

.auctioneer_screen .user {
    background: #FFFFFF;
    box-shadow: 2px 2px 6px 0px #00000026;
    border-radius: 15px;
    padding: 12px;
    max-height: 949px;
}

.complain button {
    font-size: 15px; 
    background-color: #EE3439; 
    color: #fff; 
    font-weight: 700;
    border: none;
    border-radius: 10px;
    width: 160px;
    height: 42px;
}
@media (max-width: 1200px) {
    .tab-user {
        padding: 0;
        margin: 20px 0;
    }
}
</style>

<style>
    .complain .modal-width {
        width: 373px;
    }
</style>