export default {

    methods: {
        
        __getDayOfMonth(event) {
            if (event.target.value <= 12) {
                this.valueMonth = event.target.value
            } else {
                this.valueYear = event.target.value
            }
            if (this.valueYear && this.valueMonth) {
                if (this.valueMonth >= 1 && this.valueMonth <= 12) {
                    const date = new Date(this.valueYear, this.valueMonth, 0);
                    this.valueDay = date.getDate();
                }
            }
        },
        __validatePassword() {
            if (/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(this.sendPassword)) {
                return true
            }
        },

        __openTab(tab) {
            if (tab == 'user') {
                this.formData.type = 1
            } else {
                this.formData.type = 2
            }
        },
        
        __checkNumber(event) {
            const value = event.target.value
            const name = event.target.name
            this.formData[name] = this.formatNumber(value)
        },
        __show_password(target) {
            if (target == 'pass') {
                this.$refs.inputPass.type = this.showPassword(this.$refs.inputPass.type)
                this.iconStt = !this.iconStt
            } else {
                this.$refs.inputRepass.type = this.showPassword(this.$refs.inputRepass.type)
                this.iconSttRepass = !this.iconSttRepass
            }
        },
        
    }
}
