<template>
<div style="height: 100%;">
    <div class="time">
        <img src="@/assets/icon/clock.png" alt="">
        <div class="text-center">
            <p v-if="showForTime">Cuộc đấu giá chưa diễn ra </p>
            <p v-if="!showForTime">Cuộc đấu giá đang diễn ra </p>
            <p v-if="showForTime">Thời gian đến đấu giá còn:</p>
            <p v-if="!showForTime">Thời gian trả giá còn:</p>
            <p v-if="this.timeCountStart == 0 && this.timeCount == 0">Chờ xử lý kết quả</p>
            <p v-if="this.timeCountStart !== 0">{{ countdownStart }}</p>
            <p v-if="this.timeCount !== 0 && this.timeCountStart == 0">{{ countdownEnd }}</p>
        </div>
    </div>
    <div class="table-user" v-if="showForTime">
        <div class="table-user-header">
            <div>
                <p>Điểm danh thành viên tham gia đấu giá</p>
                <p style="font-size: 16px; font-weight: 400;">Số thành viên gửi điểm danh: <span style="font-weight: 500;">{{ getDataRegister.length }}</span></p>
            </div>
            <div>
                <button @click="handleAccept('all','','', dataAsset.auction_key)">Điểm danh tất cả</button>
            </div>
        </div>
        <div class="table-user-body">
            <b-table responsive="md" :fields="pending_user.fields" :items="getDataRegister" tbody-tr-class="my-2" :class="{'setHeight' : getDataRegister.length !== 0}">
                <template #cell(member_code)="row">
                    <span @click="showInfoUser(row.item.member_id)">{{ row.item.member_code }}</span>
                </template>        
                <template #cell(is_online)="row">
                    <span :class="{
                                    'onl': row.item.is_online === '1',
                                }" v-show="row.item.is_online == 1">
                        Online
                    </span>
                    <span :class="{
                                    'off': row.item.is_online === '0'
                                }" v-show="row.item.is_online == 0">
                        Offline
                    </span>
                </template>
                <template #cell(accept)="row">
                    <span @click="handleAccept(row.item.member_code, row.item.member_id, row.item.member_socket_id, dataAsset.auction_key)" class="btn-accept">Điểm danh</span>
                </template>
            </b-table>
        </div>
        <p class="text-center no-data" v-if="getDataRegister.length == 0">Không có yêu cầu duyệt mới</p> 
    </div>
    <!-- TRẢ GIÁ -->
    <div class="table-user table-bid" v-if="!showForTime">
        <div class="table-bid-header">
            <div class="d-flex align-items-center justify-content-between flex-wrap" style="margin-bottom: 20px;" v-if="this.timeCount == 0 && showForTimeEnd">
                <p style="font-weight: 500; font-size: 18px; margin: 0;">Kết quả cuộc đấu giá:</p>
                <div v-if="this.room_status == 3">
                    <button style="margin-right: 10px;"
                        @click="showReasonFail(dataAsset.asset_name, dataAsset.end_time, dataAsset.asset_code, dataAsset.auction_key, dataAsset.asset_id)" 
                    >Cuộc đấu giá không thành</button>
                    <button @click="showSelectWinner(dataAsset.asset_name, dataAsset.asset_id, dataAsset.auction_key)" class="btn-success">Cuộc đấu giá thành </button>
                    <button @click="showDif(dataAsset.asset_name, dataAsset.end_time, dataAsset.asset_code, dataAsset.auction_key, dataAsset.asset_id)" class="btn-dif">Khác </button>
                </div>
            </div>
            <p style="font-weight: 500; font-size: 18px;" v-if="getDataHighestBid.result_status == 1">Giá trả cao nhất hiện tại: Tài sản {{ dataAsset.asset_code }}</p>
            <div class="highest" v-if="getDataHighestBid.result_status == 1 &&  getDataHighestBid.highest_bid.member_code">
                <p>{{ getDataHighestBid.highest_bid.member_code }}</p>
                <p>{{ getDataHighestBid.highest_bid.member_name }}</p>
                <p>{{ getDataHighestBid.highest_bid.asset_code }}</p>
                <p>{{ formateTime(getDataHighestBid.highest_bid.time) }}</p>
                <p class="text-red">{{ parseInt(getDataHighestBid.highest_bid.price).toLocaleString("vi-VN") }} VNĐ</p>
            </div>
            <div class="highest" v-else></div>
        </div>
        <div class="table-user-body">
            <div class="d-flex align-items-center justify-content-between">
                <p style="font-weight: 500; font-size: 18px;">Lịch sử trả giá: </p>
                <p style="color: #EE3439; font-weight: 500; font-size: 15px;" v-if="getDataAllBid.length !== 0">Số lượt trả giá: {{ getDataAllBid.length }}</p>
            </div>
            <b-table responsive="md" :fields="table_bid.fields" :items="getDataAllBid" tbody-tr-class="my-2" class="setHeight">
                <template #cell(member_code)="row">
                    <span :class="{'return_price':  row.item.kick == 1}" >
                        {{ row.item.member_code }}
                    </span>
                </template>
                <template #cell(member_name)="row">
                    <span :class="{'return_price':  row.item.kick == 1}" >
                        {{ row.item.member_name }}
                    </span>
                </template>
                <template #cell(asset_code)="row">
                    <span :class="{'return_price':  row.item.kick == 1}" >
                        {{ row.item.asset_code }}
                    </span>
                </template>
                <template #cell(price)="row">
                    <span :class="{'return_price': row.item.withdraw_status == 1 || row.item.kick == 1}" v-if="row.item.price == null">
                        0 VNĐ
                    </span>
                    <span :class="{'return_price': row.item.withdraw_status == 1 || row.item.kick == 1}" v-else>
                        {{ parseInt(row.item.price).toLocaleString("vi-VN") }} VNĐ
                    </span>
                    <span v-show="row.item.withdraw_status == 1" class="text-red">
                        Rút giá
                    </span>
                </template>

                <template #cell(time)="row">
                    <span style="font-weight: 700; opacity: 0.7; color: #111111;" :class="{'return_price': row.item.kick == 1}">{{ formateTime(row.item.time) }}</span>
                    <span style="font-weight: 500; display: block;" :class="{'return_price': row.item.kick == 1}">{{ formateDate(row.item.time) }}</span>
                </template>
            </b-table>
        </div>
    </div>
    <modalAccept ref="modalAccept" @approveMember="approveMember"/>
    <!-- <modalStart ref="modalStart" :dataProp=showModal v-if="this.timeCountStart == 0 && !showForTime && this.room_status == 0" :dataAuction="dataAuction"/> -->
    <modalEnd ref="modalEnd" :dataProp=showModal v-if="this.timeCount == 0  && showForTimeEnd && this.room_status == 3 "/>
    <statusAuction ref="statusAuction" @confirmRoom="confirmRoom"/>
    <infoUser ref="infoUser" />
</div>
</template>

<script>
import modalAccept from '../modal/accept.vue';
// import modalStart from '../modal/modalStart.vue';
import modalEnd from '../modal/modalEnd.vue';
import statusAuction from '../modal/status_auction.vue';
import infoUser from '../modal/info_user.vue';
import { state } from "@/service/socket";
export default {
    components: {
        modalAccept,
        // modalStart,
        modalEnd,
        statusAuction,
        infoUser
    },
    data() {
        return {
            pending_user: {
                fields: [
                    { key: 'member_code', label: 'Mã khách hàng', thClass: 'text-center col-md-2', tdClass: 'text-center col-md-2' },
                    { key: 'member_name', label: 'Họ và tên', thClass: 'text-center col-md-3', tdClass: 'text-center col-md-3' },
                    { key: 'asset_code', label: 'Mã tài sản', thClass: 'text-center col-md-2', tdClass: 'text-center col-md-2' },
                    { key: 'is_online', label: 'Online', thClass: 'text-center col-md-1', tdClass: 'text-center col-md-1' },
                    { key: 'accept', label: '', thClass: 'text-center col-md-2 th-btn', tdClass: 'text-center col-md-2' },
                ],
                items: [],
            },
            table_bid: {
                fields: [
                    { key: 'member_code', label: 'Mã khách hàng', thClass: 'text-center col-md-2', tdClass: 'text-center col-md-2' },
                    { key: 'member_name', label: 'Họ và tên', thClass: 'text-center col-md-3', tdClass: 'text-center col-md-3' },
                    { key: 'asset_code', label: 'Mã tài sản', thClass: 'text-center col-md-2', tdClass: 'text-center col-md-2' },
                    { key: 'time', label: 'Thời gian', thClass: 'text-center col-md-2', tdClass: 'text-center col-md-2' },
                    { key: 'price', label: 'Giá trả', thClass: 'text-center col-md-3', tdClass: 'text-center col-md-3' },
                ],
                items: [],
            },
            showModal: true,
            showForTime: null,
            showForTimeEnd: true,
            auctionId: '',
            dataAsset: [],
            room_status: '',
            timeCount: 0,
            timeCountStart: 0,
            dataAuction: [],
            token: '',
            asset_id: '',
            socket: null
        }
    },
    computed: {
        countdownStart() {
            return this.timeCountStart ? this.formatCountdown(this.timeCountStart, 'hour') : '00 giờ      00 phút      00 giây';
        },
        countdownEnd() {
            return this.timeCount ? this.formatCountdown(this.timeCount, 'hour') : '00 giờ      00 phút      00 giây';
        },
        getDataRegister() {
            return state.dataRegister
        },
        getDataAllBid() {
            return state.dataAllBid
        },
        getDataHighestBid() {
            return state.dataHighestBid
        },
    },
    created() {
        const storedData = localStorage.getItem('auInfo')
        if (storedData) {
            var info = JSON.parse(storedData)
            this.token = info.accessToken
        }
        this.auctionId = this.decode(this.$route.params.auction_id)
        this.getListAsset();
    },
    methods: {
        handleAccept(code, id, socket_id, auction_key) {
            this.$refs.modalAccept.show(code, id, socket_id, auction_key)
        },
        async getData() {
            const res = await this.$axios.get('/asset/getAssetDetail?asset_id=' + this.asset_id, {
                headers: {
                    'x-access-token': this.token
                }
            })
            if(res.status == 200) {
                this.dataAsset = res.data
                this.dataAuction = [res.data.auction_key]
                //GET SOCKET DATA
                const key = '';
                const token = JSON.parse(localStorage.getItem('auInfo')).accessToken;
                this.socket = this.$socket(this.dataAsset.auction_key ,key, token)
                // this.socket.connect()
                this.socket.on('room_status', (res) => {
                    this.room_status = res.room_status 
                })
                this.socket.on('countdown', (res) => {
                    this.timeCount = res
                    if(res > 0) {
                        this.showForTimeEnd = false
                    } else {
                        this.showForTimeEnd = true
                        this.timeCount = 0
                    }
                })
                this.socket.on('countdownStart', (res) => {
                    this.timeCountStart = res
                    if(res > 0) {
                        this.showForTime = true
                    } else {
                        this.showForTime = false
                        this.timeCountStart = 0
                    }
                })
            }
        },
        formateTime(time) {
            return this.$moment.utc(time).format("HH:mm:ss:SSS")
        },
        formateDate(time) {
            return this.$moment.utc(time).format("DD/MM/YYYY")
        },
        showReasonFail(title, time, code, auction_key, id) {
            this.$refs.statusAuction.showReasonFail(title, time, code, auction_key, id)
        },
        showSelectWinner(title, id, auction_key) {
            this.$refs.statusAuction.showSelectWinner(title, id, auction_key)
        },
        showDif(title, time, code, auction_key, id) {
            this.$refs.statusAuction.showDif(title, time, code, auction_key, id)
        },
        formatCountdown(time) {
            const duration = this.$moment.duration(time, 'seconds');
            const hours = Math.floor(duration.asHours());
            const minutes = duration.minutes();
            const seconds = Math.floor(time % 60);
            return (
                (hours < 10 ? '0' + hours : hours) + ' giờ       ' +
                (minutes < 10 ? '0' + minutes : minutes) + ' phút       ' +
                (seconds < 10 ? '0' + seconds : seconds) + ' giây '
            );
        },
        showInfoUser(id) {
            this.$refs.infoUser.show(id)
        },
        async getListAsset() {
            const res = await this.$axios.get('/asset/getListAsset', {
                params: {
                    asset_name: '',
                    asset_code: '',
                    category_id: '',
                    owner: '',
                    from_price: '',
                    to_price: '',
                    from_step_price: '',
                    to_step_price: '',
                    order: 'latest',
                    page: '1',
                    per_page: '100000',
                    winner: '',
                    status: '',
                    description: '',
                    auction_id: this.auctionId,
                    publish_status: '',
                    asset_status: ''
                },
                headers: {
                    'x-access-token': this.token
                }
            }).then(res => {
                this.asset_id = res.data.result[0].asset_id
                this.getData()
            }).catch(err => {
                if(err.response.status == 409) {
                    localStorage.removeItem('auInfo')
                    this.$toast.error('Tài khoản được đăng nhập từ thiết bị khác!')
                    this.$router.push('/loginAuctioneer')
                } else if (err.response.status == 401) {
                    localStorage.removeItem('auInfo')
                    this.$toast.error('Phiên đăng nhập đã hết hạn!')
                    this.$router.push('/loginAuctioneer')
                }
            })
        },
        approveMember(data, id, socket_id) {
            if(data == 'all') {
                this.socket.emit("approveMember", {member_id: "", member_socket_id: ""})
            } else {
                this.socket.emit("approveMember", {member_id: id, member_socket_id: socket_id})
            }
        },
        confirmRoom(data) {
            this.socket.emit("confirmRoom", data)
        }
    },
}
</script>

<style lang="css" scoped>
    .auctioneer_screen .time {
        position: relative;
        background-color: #FFFFFF;
        border-radius: 20px;
        box-shadow: 0px 4px 4px 0px #00000040;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 12px;
    }

    .auctioneer_screen .time img {
        position: absolute;
        left: 2%;
    }
    .auctioneer_screen .time p {
        margin-bottom: 0;
        color: #EE3439;
        font-size: 20px;
        font-weight: 500;
    }

    .auctioneer_screen .time p:nth-child(2) {
        font-size: 16px;
    }
    .auctioneer_screen .time p:nth-child(3) {
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 5px;
        font-family: Libre Bodoni;
        white-space: break-spaces;
    }

    .auctioneer_screen .table-user {
        box-shadow: 2px 2px 6px 0px #00000026;
        background-color: #FFFFFF;
        border-radius: 15px;
        height: 842px;
        padding: 12px 11px 0 12px;
        overflow-y: hidden;
    }

    .setHeight {
        height: 600px;
        overflow-y: auto;
    }

    .table-responsive-md::-webkit-scrollbar {
        width: 0px;
    }

    .auctioneer_screen .table-user-header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .auctioneer_screen .table-user-header p {
        color: #515151;
        font-size: 18px;
        line-height: 32.4px;
        font-weight: 500;
        margin: 0;
    }

    .auctioneer_screen .table-user-header button {
        font-weight: 500;
        font-size: 15px;
        border: 1px solid #EE3439;
        border-radius: 15px;
        color: #EE3439;
        padding: 7px 32px;
        max-width: 180px;
        max-height: 38px;
    }

    .auctioneer_screen .table-user::-webkit-scrollbar {
        width: 0px;
    }

    .auctioneer_screen .onl, .off {
        position: relative;
        padding-left: 10px;
        display: flex;
        align-items: center;
        font-size: 13.03px;
        line-height: 23.45px;
        font-weight: 500;
    }
    .auctioneer_screen .onl {
        color: #00B569;
    }
    .auctioneer_screen .onl::before,
    .auctioneer_screen .off::before {
        content: '';
        position: absolute;
        width: 7px;
        height: 7px;
        border-radius: 20px;
        left: 0;
    }
    .auctioneer_screen .onl::before {
        background-color: #00B569;
    }
    .auctioneer_screen .off::before {
        background-color: #EE3439;
    }
    .auctioneer_screen .off {
        color: #EE3439;
    }

    .table-user .no-data {
        font-size: 18px;
        color: #A3A3A3;
        margin: 0;
        height: 80%;
        font-weight: 400;
        background-image: url('@/assets/images/logo_moreinfo.png');
        background-repeat: no-repeat;
        background-position: top;
    }

    .highest {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 20px;
        background: #E7E7E7;
        border-radius: 15px;
        padding: 14px;
        color: #515151;
        font-weight: 500;
    }
    .highest p {
        margin: 0;
    }

    .text-red {
        color: #EE3439;
        font-weight: 500;
        display: block;
    }

    .return_price {
        color: #EE3439 !important;
        text-decoration: line-through;
        opacity: 1 !important;
    }
    .table-bid-header button {
        width: 210px;
        height: 36px;
        border: 1px solid #EE3439;
        color: #EE3439;
        border-radius: 15px;
    }
    .table-bid-header button.btn-success {
        background-color: #EE3439;
        color: #FFFFFF;
        border: none;
        margin: 10px 10px 0 0;
    }

    .table-bid-header button.btn-dif {
        background-color: #FF9838;
        color: #FFFFFF;
        border: none;
        margin-top: 10px;
        width: 95px;
    }

    @media (max-width: 425px) {
        .auctioneer_screen .table-user-header button {
            margin: 15px 0;
        }
    }
</style>
<style lang="css">
    .modal-start .modal-width {
        width: 373px;
    }
    .auctioneer_screen button {
        background: none;
        border: none;
    }

    .auctioneer_screen .cz-thumblist-item>img {
        /* max-width: 100px; */
        max-height: 70px;
        border-radius: 3px;
        object-fit: cover;
    }

    .auctioneer_screen .show-imag {
        height: 100%;
        max-width: 600px;
        margin: 0 auto;
        display: block;
        object-fit: cover;
    }

    .auction_search .form-group {
        margin-bottom: 0;
    }

    .auctioneer_screen thead {
        position: sticky;
        top: -12px;
        z-index: 99;
        height: 55px;
        background: #FFFFFF;
    }

    .auctioneer_screen thead tr {
        font-size: 15px;
        color: #515151;
        vertical-align: middle;
    }

    .auctioneer_screen thead tr th {
        font-weight: 500;
    }

    .auctioneer_screen .table > :not(caption) > * > * {
        border: none;
    }

    .auctioneer_screen tr {
        border-bottom: 1px solid #8a8383;
    }

    .auctioneer_screen tr:last-child {
        border-bottom: none;
    }

    .auctioneer_screen tbody tr {
        background: #F8F8F8;
        border-radius: 14px;
        vertical-align: middle;
    }

    .auctioneer_screen tbody td {
        font-size: 15px;
        color: #515151;
        font-weight: 500;
    }

    .auctioneer_screen .th-btn{
        max-width: 117px;
    }

    .auctioneer_screen .btn-accept {
        border-radius: 14px;
        font-size: 14.92px;
        font-weight: 500;
        padding: 6px 0;
        display: block;
        cursor: pointer;
        background-color: #EE3439;
        color: #FFFFFF;
    }

    .auctioneer_screen tbody tr td:first-child {
        color: #3FA5FF;
        text-decoration: underline;
        cursor: pointer;
    }

    .auctioneer_screen .table-bid tbody tr td:first-child {
        color: #515151;
        text-decoration: none;
        cursor: unset;
    }
</style>