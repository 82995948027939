<template>
    <div>
        <b-container class="product-auction">
            <b-breadcrumb :items="items"></b-breadcrumb>
            <b-row>
                <div class="d-flex head">
                    <h5>Tài sản sắp được đấu giá</h5>
                    <b-form class="form-search" @submit.prevent="getListAssetForMember(params)">
                        <b-form-group id="input-group-1" label-for="input-1" >
                            <b-form-input id="input-1" placeholder="Tìm kiếm tài sản đấu giá"
                                v-model="params.asset_name"></b-form-input>
                            <img class="search_ico" src="@/assets/icon/Search.png" alt="" @click="getListAssetForMember(params)">
                        </b-form-group>
                    </b-form>
                </div>
                <div class="col-12">
                    <carousel :responsive="{
                        0: {
                            nav: false,
                            dots: false,
                            items: 1
                        },
                        645: {
                            items: 2,
                            nav: false,
                            dots: false,
                        },
                        768: {
                            items: 2,
                            nav: false,
                            dots: false,
                        },
                        992: {
                            items: 3,
                            nav: false,
                            dots: false,
                        },
                        1200: {
                            items: 4,
                            nav: false,
                            dots: false,
                        },
                    }" :autoplay="true" :margin="10" :key="carouselKey">
                        <b-link v-for="(item, index) in listAsset.result" :key="index">
                            <div class="scroll-mobie d-flex justify-content-center" @click="$router.push('/product-detail/' + encode(item.asset_id))" >
                                <cardAsset :item="item"/>
                            </div>
                        </b-link>
                    </carousel>
                </div>
            </b-row>
            <h5 class="category">Danh mục đấu giá</h5>
            <!-- <b-row > -->
                <assetByCategory v-for="(cate, index) in listCategory" :key=index style="margin-bottom: 38px; justify-content: center;" :cate="cate"/>
            <!-- </b-row> -->
            <div class="mb-2" v-if="listNews.result">
                <h5>Tin tức và thông báo mới</h5>
                <div class="slide news">
                    <carousel :responsive="{
                        0: {
                            nav: false,
                            dots: false,
                            items: 1
                        },
                        425: {
                            items: 2,
                            nav: false,
                            dots: false,
                        },
                        768: {
                            items: 2,
                            nav: false,
                            dots: false,
                        },
                        992: {
                            items: 3,
                            nav: false,
                            dots: false,
                        },
                        1200: {
                            items: 4,
                            nav: false,
                            dots: false,
                        },
                    }" :autoplay="false" :margin="10">
                        <div class="slide-item" v-for="(item, index) in Array.from(listNews.result)" :key="index">
                            <cardNew :item="item"></cardNew>
                        </div>
                    </carousel>
                </div>
            </div>
        </b-container>
        
    </div>
</template>
<script>
import assetByCategory from "./components/assetByCategory.vue";
import carousel from "vue-owl-carousel";
import cardNew from "@/pages/web-view/components/card-news.vue"
import cardAsset from "./components/card-asset.vue";
export default {
    components: {
        carousel,
        cardNew,
        cardAsset,
        assetByCategory
    },
    methods:{},
    mounted(){
        this.getListAssetForMember(this.params)
        this.getListCategoryForMember(this.paramsCate)
        this.getListNews(this.paramsNew)
    },
    data() {
        return {
            carouselKey: 0,
            params:{
                asset_name:"",
                asset_code:"",
                asset_status:"0",
                category_id:"",
                owner:"",
                from_price:"",
                to_price:"",
                from_step_price:"",
                to_step_price:"",
                page: 1,
                per_page: 10,
                order: "latest",
                winner:"",
                status:"",
                description:"",
                auction_id:"",
            },
            paramsCate:{
                name: "",
                code: "",
                status: 1,
                parent_id: null,
                page: 1,
                per_page: 6,
            },
            paramsNew:{
                page:1,
                per_page:10,
            },
            items: [
                {
                    text: 'Trang chủ',
                    to: '/',
                },
                {
                    text: 'Tài sản đấu giá',
                    active: true
                },
            ],
            listNews: {},
            listAsset: {},
            listCategory:[],
        }
    },
}
</script>

<style lang="css" scoped>
.product-auction {
    color: #515151;
}

.product-auction .head {
    justify-content: space-between;
    margin-bottom: 20px;
}

h5 {
    font-family: Libre Bodoni;
    font-size: 24px;
    font-weight: 500;
    color: #515151;
    align-items: flex-end;
    display: flex;
}

.slide-card{
    display: flex;
    overflow: auto;
}
.product-auction .head .title {
    font-size: 24px;
    line-height: 30px;
    font-family: Libre Bodoni;
    border: none;
}

.product-auction .form-search {
    position: relative;
}

.product-auction .form-search .form-control {
    border: 1px solid #B3B3B3;
    border-radius: 39px;
    width: 358px;
    height: 47px;
    padding-right: 80px;
}

.product-auction .form-search .form-control:focus {
    box-shadow: none;
}

.product-auction .form-search .search_ico {
    position: absolute;
    background: #515151;
    top: 2px;
    right: 2px;
    padding: 7px 21px;
    border-radius: 39px;
    cursor: pointer;
}

.product-auction .slide .slide-item {
    border: none;
    padding: 0;
    padding-bottom: 10px;
    font-size: 14px;
    line-height: 25px;
    text-align: center;
    min-height: 390px;
    border-radius: 10px;
}

.product-auction .slide .slide-item .btn {
    background-color: transparent;
    border-radius: 19px;
    color: #929292;
    text-align: left;
    font-size: 14px;
}

.product-auction .slide .slide-item__ttl {
    text-align: left;
    margin: 0;
    padding: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.product-auction .slide .slide-item .list-btn {
    margin: 0;
    padding-left: 0;
}

.product-auction .slide .slide-item .list-btn span {
    color: #515151;
    font-weight: 500;
}

.product-auction .category {
    font-size: 24px;
    line-height: 30px;
    font-weight: 500;
    margin: 24px 0 14px;
    font-family: Libre Bodoni;
}

.product-auction .sidebar_txt {
    position: absolute;
    font-size: 32px;
    line-height: 40px;
    color: #FFFFFF;
    text-align: center;
    font-family: Libre Bodoni;
    font-weight: 500;
    width: 93%;
}

.sidebar {
    align-items: center;
    height: 100%;
}

.product-auction .sidebar_txt a {
    color: #FFFFFF;
    font-size: 22.48px;
    line-height: 28.09px;
    text-decoration: none;
    border: 1px solid #FFFFFF;
    border-radius: 30px;
    padding: 7px 34px
}

.product-auction .category-item {
    display: flex;
    justify-content: space-around;
    align-items: center;
    border: 1px solid #BEBEBE;
    border-radius: 10px;
    padding: 7px;
    text-decoration: none;
}

.m-b .col-md-6:first-child {
    margin-bottom: 10px;
}

.product-auction .category-item_txt {
    font-size: 14px;
    font-weight: 500;
    font-family: Roboto;
    line-height: 25.2px;
    padding-left: 9px;
    text-align: left;
}

.product-auction .category-item_txt .category-item_quote {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 15px;
    color: #515151;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.product-auction .category-item_txt p {
    margin-bottom: 9px;
    color: #929292;
    font-weight: 400;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.product-auction .category-item_txt span {
    font-weight: 600;
    color: #515151;
    font-family: Roboto;
}

.product-auction .text-red {
    color: #EE3439 !important;
}

@media (max-width: 1024px) {
    .sidebar {
        display: none !important;
    }

    .product-auction .col-md-9 {
        width: 100%;
    }

    .product-auction .list-cate {
        display: block !important;
    }
}

@media (max-width: 768px) {

    .product-auction .category-item {
        display: block;
        text-align: center;
        margin-bottom: 15px;
    }

    .product-auction .category-item_txt {
        padding-top: 15px;
    }

    .m-b .col-md-6:first-child {
        margin-bottom: 0;
    }
}

@media (max-width: 576px) {
    .product-auction .head {
        display: block !important;
    }

    .product-auction .form-search .form-control {
        width: 100%;
    }
}
</style>