<template>
    <div :class="{ 'overlay': isLoading }">
        <b-container class="register-auction">
            <b-breadcrumb :items="items"></b-breadcrumb>
            <h5 class="title">{{ auction.asset_name }}
            </h5>
            <div class="d-lg-flex">
                <b-col cols="lg-6 ">
                    <div class="regulation">
                        <div class="text-center">
                            <h4 class="header-1">Quy chế tham gia đấu giá</h4>
                            <div class="pdfvuer">
                                <pdf :src="pdfdata" v-for="i in numPages" :key="i" :id="i" :page="i" :scale.sync="scale">
                                </pdf>
                            </div>
                        </div>
                        <div>
                            <h3 class="header-2">Quy chế riêng:</h3>
                            <ul>
                                <li>Sau khi bấm vào “Đăng ký tham gia đấu giá”, hệ thống sẽ gửi email hướng dẫn về cách thức
                                    nộp các khoản tiền và cách thức hoàn thiện hồ sơ tham gia đấu giá</li>
                                <li>Mỗi lần trả giá , khách hàng phải trả tối thiểu 1 bước giá và tối đa {{ auction.step }} lần
                                    bước giá </li>
                                <li>Khách hàng đã rút giá thành công sẽ bị truất quyền trả giá</li>
                            </ul>
                            <h3 class="header-2">
                                Hồ sơ yêu cầu khi đăng ký tham gia đấu giá ( PDF , Docx , Word ,...):
                            </h3>
                            <div class="d-flex flex-wrap">
                                <div class="flex-column" v-for="file in auction.required_file">
                                    <input class="image_input_review" type="file" hidden :name="file.required_file_id"
                                        :id="'register_profile'+ file.required_file_id" @change="uploadFile" accept="application/pdf">
                                    <img src="@/assets/front-end/img/Group 1082.png" alt="" class="review-img"
                                    :ref="'required_file_id' + file.required_file_id"
                                    @click="openFile(file.required_file_id)">
                                    <span class="file_name">
                                        {{ file.required_file_name }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-col>
                <b-col cols="lg-6" class="p-lg-0">
                    <div>
                        <div class="cz-product-gallery">
                            <div class="cz-preview col-md-6 m-0 p-0">
                                <b-link href="#">
                                    <img class="show-imag img-responsive" style="max-height: 500px!important;"
                                        v-if="selectedImage" :src="$storage + selectedImage"
                                        alt="Product image" @error="handleError"
                                        @click="showModal($storage + selectedImage)">
                                    <!-- <img class="show-imag img-responsive" style="max-height: 500px!important;" v-else
                                        :src="$storage + auction.image[0]" alt="Product image"
                                        @click="showModal($storage + auction.image[0])"> -->
                                </b-link>
                            </div>
                            <div class="col-md-6 align-items-center row m-0 p-0">
                                <div class="col-6 px-1 mt-mobie-2" v-for="(photo, key) in auction.image" v-if="key < 4">
                                    <a class="col-12 pr-0 cz-thumblist-item ">
                                        <img :src="$storage + photo" alt="Product thumb"
                                            @click="showImage(photo)" @error="handleError">
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="asset-information">
                        <h4 class="header-1 text-center">Thông tin tài sản đấu giá </h4>
                        <div class="row">
                            <div class="col-sm-6 title">Tài sản:</div>
                            <label class="col-sm-6 content">{{ auction.asset_name }}</label>
                            <div class="col-sm-6 title">Mã tài sản đấu giá:</div>
                            <div class="col-sm-6 content">{{ auction.asset_code }}</div>
                            <div class="col-sm-6 title">Thời gian bắt đầu đăng ký tham gia đấu giá:</div>
                            <div class="col-sm-6 content">{{ formateTime(auction.register_start_time) }}</div>
                            <div class="col-sm-6 title">Thời gian kết thúc đăng ký:</div>
                            <div class="col-sm-6 content">{{ formateTime(auction.register_end_time) }}</div>
                            <div class="col-sm-6 title">Giá khởi điểm:</div>
                            <div class="col-sm-6 content">{{ parseInt(auction.price).toLocaleString("vi-VN") }}VNĐ</div>
                            <div class="col-sm-6 title">Phí tham gia đấu giá:</div>
                            <div class="col-sm-6 content">{{ parseInt(auction.record_fee).toLocaleString("vi-VN") }}VNĐ</div>
                            <div class="col-sm-6 title">Bước giá:</div>
                            <div class="col-sm-6 content">{{ parseInt(auction.step_price).toLocaleString("vi-VN") }}VNĐ
                            </div>
                            <div class="col-sm-6 title">Tiền đặt trước:</div>
                            <div class="col-sm-6 content">{{ parseInt(auction.down_payment).toLocaleString("vi-VN")}} VNĐ</div>
                            <div class="col-sm-6 title">Hình thức đấu:</div>
                            <div class="col-sm-6 content" v-if="auction.type == 1">Đấu giá lên - Trả liên tục</div>
                            <div class="col-sm-6 content" v-if="auction.type == 2">Đấu giá xuống - Trả liên tục</div>
                            <div class="col-sm-6 content" v-if="auction.type == 3">Đấu giá theo vòng - Trả liên tục</div>
                            <div class="col-sm-6 content" v-if="auction.type == 4">Đấu giá lên - Trả tự do</div>
                            <div class="col-sm-6 content" v-if="auction.type == 5">Đấu giá xuống - Trả tự do</div>
                            <div class="col-sm-6 content" v-if="auction.type == 6">Đấu giá theo vòng - Trả tự do</div>
                            <div class="col-sm-6 title">Tên chủ tài sản:</div>
                            <div class="col-sm-6 content">{{ auction.owner }}</div>
                            <div class="col-sm-6 title">Nơi xem tài sản:</div>
                            <div class="col-sm-6 content">{{ auction.auction_address }}</div>
                            <div class="col-sm-6 title">Thời gian bắt đầu trả giá:</div>
                            <div class="col-sm-6 content">{{ formateTime(auction.start_time) }}</div>
                            <div class="col-sm-6 title">Thời gian kết thúc trả giá:</div>
                            <div class="col-sm-6 content">{{ formateTime(auction.end_time) }}</div>
                        </div>
                    </div>
                    <b-form-group class="my-3">
                        <div class="form-check" v-for="(option, index) in options">
                            <input class="form-check-input" type="checkbox" :value="option.value" :id="'label' + index"
                                @input="checkInput(option.value)">
                            <label class="form-check-label" :for="'label' + index">
                                {{ option.text }}
                            </label>
                        </div>
                    </b-form-group>
                    <div class="d-flex justify-content-end mb-3">
                        <a href="#">
                            <button class="btnn btn-outline">Hủy bỏ</button>
                        </a>
                        <div>
                            <button @click="memberRegisterForAuction()" class="btnn btn-primari" :disabled="selected.length < 2">
                                Tham gia đấu giá
                            </button>
                        </div>
                    </div>
                    <!-- ---Modal--- -->
                    <b-modal id="" ref="imgModal" size="lg" centered hide-footer hide-header content-class="image-auction">
                        <img :src="this.imgModal" alt="" style="border-radius: 15px;">
                    </b-modal>
                </b-col>
            </div>
            <noticeRegisterAuction ref="noticeRegisterAuction" :asset_id="auction.asset_id"/>
        </b-container>
        <div class="loader" v-if="isLoading"></div>
    </div>
</template>
<script>
import pdfvuer from 'pdfvuer'
import noticeRegisterAuction from './modal/noticeRegisterAuction.vue'
export default {
    components: {
        pdf: pdfvuer,
        noticeRegisterAuction
    },
    data() {
        return {
            items: [
                {
                    text: 'Trang chủ',
                    href: '/',
                },
                {
                    text: 'Đăng kí đấu giá',
                    active: true
                },
            ],
            
            auction: {
                image: []
            },
            selected: [], // Must be an array reference!
            options: [
                { text: 'Tôi đã đọc và đồng ý với quy chế đấu giá trên.', value: 'title-1' },
                { text: 'Tôi đã hiểu rõ về tài sản đấu giá và không có ý kiến gì về tài sản đấu giá.', value: 'title-2' },
                // { text: 'Tôi tự nguyện không nhận tiền lãi phát sinh từ khoản tiền đặt trước.', value: 'tiile-3' },
            ],
            page: 1,
            numPages: 0,
            pdfdata: '',
            scale: 'page-width',
            selectedImage: null,
            imgModal: '',
            member:JSON.parse(localStorage.getItem('userInfo')),
            required_file:[],
            isLoading:false,
            auctionDetail:{},
        }
    },
    mounted() {
        this.getData()
    },
    methods: {
        async memberRegisterForAuction() {
            let params = {
                auction_id: this.auction.auction_id,
                member_id: this.member.id,
                asset_id: this.auction.asset_id,
                required_file: this.required_file
            }
            await this.$axios.post('/auction/memberRegisterForAuction ', params, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': this.member.accessToken,
                }
            }).then(res => {
                this.$refs.noticeRegisterAuction.show()
            }).catch(err => {
                if (err.code == "ERR_NETWORK") {
                    this.$toast.error('Lỗi hệ thống')
                } else {
                    this.$toast.error(err.response.data.error)
                }
            })
        },
        openFile(id){
            $('#register_profile'+id).click()
        },
        async uploadFile(event) {
            this.isLoading = true
            let name = event.target.name
            let ref = 'required_file_id'+name
            await this.$axios.post('/common/uploadFile', {
                file: event.target.files[0],
                path: 'member/required_file/',
            }, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((res) => {
                if (event.target.files[0].type == 'application/pdf') {
                    this.$refs[ref][0].src = require('@/assets/admin/icon/Import Pdf.png')
                }else{
                    this.$refs[ref][0].src = this.$storage + '/' + res.data.list_result[0].path_tam
                }
                this.required_file.forEach(element => {
                    if (element.required_file_id == name) {
                        element.url = res.data.list_result[0].path
                    }
                });
            })
            this.isLoading = false
        },
        async getData() {
            await this.$axios.get('/asset/getAssetDetail', {
                params: {
                    asset_id: this.decode(this.$route.params.id)
                },
                headers: {
                    'x-access-token': this.member.accessToken
                }
            }).then((res) => {
                this.auction = res.data
                this.selectedImage = res.data.image[0]
                this.pdfdata = this.$storage + res.data.regulation
                res.data.required_file.forEach(element => {
                    this.required_file.push({
                        url:'',
                        required_file_id:element.required_file_id
                    })
                });
                this.getPdf()
                this.getAuctionDetailForMember({auction_id: this.auction.auction_id })
            })
        },
        getPdf() {
            var self = this;
            self.pdfdata = pdfvuer.createLoadingTask(this.pdfdata);
            self.pdfdata.then(pdf => {
                self.numPages = pdf.numPages;
            });
        },
        findPos(obj) {
            return obj.offsetTop;
        },
        checkInput(value) {
            var flag = 0
            this.selected.forEach(e => {
                if (e == value) {
                    flag = 1
                }
            });
            if (flag == 0) {
                this.selected.push(value)
            } else {
                const removeIndex = this.selected.findIndex((item) => item === value);
                this.selected.splice(removeIndex, 1);
            }
        },
        showImage(image) {
            this.selectedImage = image;
        },
        showModal(url) {
            this.$refs.imgModal.show()
            this.imgModal = url;
        }
    }
}
</script>

<style scoped lang="css">
.register-auction {
    background-image: url('../../assets/front-end/img/logo 4.png');
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: 0.5rem;
} 
.file_name{
    width: 125px;
}
.register-auction .regulation {
    font-family: Roboto;
    color: #515151;
    background-color: #BEBEBE;
    padding: 0.5rem;
    border-radius: 10px;
}
.review-img{
    width: 94px;
    height: 64px;
}
.register-auction .asset-information {
    font-family: Roboto;
    color: #515151;
    padding: 0.5rem;
    background-color: #BEBEBE50;
    margin-top: 0.5rem;
    border-radius: 10px;
}

.register-auction .header-1 {
    font-weight: 600;
    font-size: 22px;
}

.register-auction .header-2 {
    font-weight: 500;
    font-size: 18px;
}

.register-auction .regulation li {
    list-style-type: disc;
    margin-left: 1.5rem;
}

.register-auction .flex-column {
    display: flex;
    align-items: center;
    text-align: center;
    padding-right: 1rem;
}

.register-auction .cz-product-gallery .cz-thumblist-item {
    height: auto;
    margin: 0;
    border: unset;
}

a {
    text-decoration: none;
}

button:disabled {
    filter: contrast(0.5)
}

.register-auction .form-check-input:focus {
    box-shadow: unset;
    border: 1px solid #00000040;
}

.register-auction .cz-product-gallery .cz-thumblist-item img {
    height: 98px;
    width: 100%;
    border-radius: 5px;
}

.asset-information .row {
    margin-right: 0;
    margin-left: 0;
    margin-top: 0;
}

.register-auction .show-imag {
    width: 100%;
    height: 205px;
    border-radius: 10px;
    object-fit: cover;
}

.asset-information .title {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
    color: #818181;
    padding: 0.5rem;
    padding-left: 1rem;
}

.asset-information .content {
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
    color: #515151;
    margin: 0.5rem 0;
}

.register-auction input[type="checkbox" i] {
    border-radius: 20px;

}

.register-auction input[type="checkbox" i]:checked {
    background-color: #EB3223;
    border: #EB3223 solid 1px;
}

.check-valid label {
    font-weight: 400;
    font-size: 16px;
}

.register-auction .btn-primari {
    width: fit-content;
    padding-left: 3rem;
    padding-right: 3rem;
    color: #ffffff;
    background-color: #C22924;
    border: #C22924 solid 1px;
    font-weight: 700;
    font-size: 18px;
}

@media (max-width: 992px) {
    .p-lg-0 {
        margin-top: 0.5rem;
    }
}

@media (max-width: 768px) {
    .col-lg-6 {
        padding: 0;
        margin-bottom: 0.5rem;
    }

}

@media (max-width: 575px) {
    .register-auction .cz-product-gallery .cz-thumblist-item {
        padding: 0;
    }

    .mt-mobie-2 {
        margin-top: 0.5rem !important;
    }
}

@media (max-width: 425px) {
    .register-auction .btn-primari {
        font-size: 14px;
        padding-left: 1rem;
        padding-right: 1rem;
    }
}
</style>

<style lang="css">
.pdfvuer {
    height: 650px;
    overflow-y: scroll;
    overflow-x: hidden;
    margin: 25px 30px 40px;
}

.pdfvuer .page,
.pdfvuer .page .canvasWrapper,
.pdfvuer .page .canvasWrapper canvas,
.pdfvuer .page .textLayer {
    width: 100% !important;
    height: auto !important;
}

.pdfvuer::-webkit-scrollbar {
    width: 6px;
}

.pdfvuer::-webkit-scrollbar-thumb {
    background: #D6B877;
}

.modal-img {
    background-color: transparent;
    border: none;
    width: max-content;
}

.image-auction .modal-body {
    padding: 0;
}
</style>