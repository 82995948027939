<template>
    <div>
        <b-container class="wait_room">
            <div class="d-flex justify-content-between">
                <b-breadcrumb :items="items"></b-breadcrumb>
            </div>
            <b-row>
                <h5 class="title">{{ dataAsset.asset_name }}</h5>
                <b-col cols="lg-6">
                    <slideImage :list_images=dataAsset.image />
                    <div v-if="dataAsset.asset_status !== 0">
                        <img src="@/assets/icon/Auction2.png" alt="" class="image-status"
                            v-if="dataAsset.asset_status == 1">
                        <img src="@/assets/icon/golden sold out.png" alt="" class="image-status" v-else>
                    </div>
                </b-col>
                <b-col cols="lg-6">
                    <div class="show-time">
                        <p v-if="timeStart">Cuộc đấu giá chưa diễn ra</p>
                        <p v-else>Cuộc đấu giá đang diễn ra</p>
                        <p v-if="timeStart">Thời đến đấu giá còn: </p>
                        <p v-else>Thời gian trả giá còn: </p>
                        <p>{{ countdown }}</p>
                    </div>
                    <div class="info">
                        <p>Trạng thái:</p>
                        <span style="font-size: 18px; font-weight: 500; color: #EE3439;"
                            v-if="dataAsset.asset_status == 0">Chưa đấu giá</span>
                        <span style="font-size: 18px; font-weight: 500; color: #00B569;"
                            v-if="dataAsset.asset_status == 1">Đang đấu giá</span>
                        <span style="font-size: 18px; font-weight: 500; color: #00B569;"
                            v-if="dataAsset.asset_status == 2">Đấu giá xong - chờ xác nhận</span>
                        <span style="font-size: 18px; font-weight: 500; color: #EE3439;"
                            v-if="dataAsset.asset_status == 3">Đấu giá không thành</span>
                        <span style="font-size: 18px; font-weight: 500; color: #00B569;"
                            v-if="dataAsset.asset_status == 4">Đấu giá thành</span>
                    </div>
                    <div class="info">
                        <p>Giá khởi điểm:</p> <span class="highlight">{{ parseInt(dataAsset.price).toLocaleString("vi-VN")
                        }} VNĐ</span>
                    </div>
                    <div class="info">
                        <p>Mã tài sản đấu giá:</p> <span>{{ dataAsset.asset_code }}</span>
                    </div>
                    <div class="info" v-show="!isShow">
                        <p>Kết quả đấu giá:</p> <span class="auction--success">Bạn đã trúng đấu giá</span>
                    </div>
                    <div class="info">
                        <p>Thời gian bắt đầu đăng ký:</p> <span>{{ formateTime(dataAsset.register_start_time) }}</span>
                    </div>
                    <div class="info">
                        <p>Thời gian kết thúc đăng ký:</p> <span>{{ formateTime(dataAsset.register_end_time) }}</span>
                    </div>
                    <div class="info">
                        <p>Tiền mua hồ sơ:</p> <span>{{
                            parseInt(dataAsset.record_fee).toLocaleString("vi-VN") }} VNĐ</span>
                    </div>
                    <div class="info">
                        <p>Bước giá:</p> <span>{{ parseInt(dataAsset.step_price).toLocaleString("vi-VN") }} VNĐ</span>
                    </div>
                    <div class="info">
                        <p>Tiền đặt trước:</p> <span>{{ parseInt(dataAsset.down_payment).toLocaleString("vi-VN") }}
                            VNĐ</span>
                    </div>
                    <div class="info">
                        <p>Tên chủ tài sản:</p> <span>{{ dataAsset.owner }}</span>
                    </div>
                    <div class="info">
                        <p>Thời gian bắt đầu trả giá:</p> <span>{{ formateTime(dataAsset.auction_start_time) }}</span>
                    </div>
                    <!-- <div v-if="timeStart"> -->
                        <b-link :to="'/auctioneer/list-approve/' + encode(dataAsset.asset_id)" style="font-weight: 600; color: #EE3439;"><img src="@/assets/icon/Note.png" alt=""> Danh sách đăng ký tham gia đấu giá >></b-link>
                        <b-link :to="'/auctioneer/list-authen/' + encode(dataAsset.auction_id)" style="font-weight: 600; color: #EE3439; margin-top: 8px; display: block;"><img src="@/assets/icon/Note.png" alt=""> Yêu cầu duyệt ủy quyền tham gia đấu giá</b-link>
                    <!-- </div> -->
                    <div class="text-right mt-3">
                        <b-link @click="$router.push('/auctioneer/asset-detail/' + encode(dataAsset.asset_id))" class="btn-link">Chi tiết tài
                            sản đấu giá</b-link>
                        <div v-if="!creatRoom" style="display: inline;">
                            <b-link class="btn-link btn-auction" @click="$router.push('/auctioneer/list-approve/' + encode(dataAsset.asset_id))">Duyệt đăng ký tham gia</b-link>
                        </div>
                        <div v-else style="display: inline;">
                            <b-link class="btn-link btn-auction" v-if="dataAsset.create_room_status == 0"
                                @click="createRoom(dataAsset.auction_key, encode(dataAsset.auction_id))"> Tạo phòng đấu giá</b-link>
                            <b-link class="btn-link btn-auction" v-else
                                @click="$router.push('/auctioneer/auction-room/' + encode(dataAsset.auction_id))"> Vào lại phòng đấu giá</b-link>
                        </div>
                    </div>
                </b-col>
            </b-row>

            <b-row style="margin-top: 25px;" class="file">
                <b-col cols="md-4">
                    <div class="more-info">
                        <h5 class="more-info__ttl">Tài liệu liên quan</h5>
                        <ul class="list-info">
                            <li v-for="(datafile, index) in dataAsset.file" :key="index"><b-link :href=" $storage + datafile.url"
                                    target="_blank">{{ datafile.file_name }}</b-link></li>
                        </ul>
                    </div>
                </b-col>
                <b-col cols="md-4">
                    <div class="more-info">
                        <h5 class="more-info__ttl">Thông tin tổ chức đấu giá</h5>
                        <ul class="list-info-cus">
                            <li><img src="@/assets/icon/Company.png" alt="">Đơn vị tổ chức: <span class="text-red">{{
                                dataAsset.org }}</span></li>
                            <li><img src="@/assets/icon/Customer.png" alt="">Đấu giá viên: <span class="text-red">{{
                                dataAsset.DGV }}
                                </span></li>
                            <li><img src="@/assets/icon/Location.png" alt="">Địa điểm: <span class="text-red">{{
                                dataAsset.auction_address }}
                                </span></li>
                        </ul>
                    </div>
                </b-col>
            </b-row>

            <b-row v-show="!isShow">
                <detailAu />
            </b-row>
        </b-container>
    </div>
</template>
<script>
import slideImage from "../../web-view/components/slideImage.vue"
import detailAu from './components/detail-auction.vue'
export default {
    components: {
        slideImage,
        detailAu
    },
    data() {
        return {
            items: [
                {
                    text: 'Trang chủ',
                    to: '/',
                },
                {
                    text: 'Điểm danh đấu giá',
                    active: true
                },
            ],
            timeLeft: 0,
            isShow: true,
            step: 5000000,
            currentTime: '',
            asset_id: '',
            dataAsset: [],
            timeStart: true,
            creatRoom: false,
            socket: null
        }
    },
    computed: {
        countdown() {
            return this.timeLeft ? this.formatTime(this.timeLeft, 'hour') : '00 giờ      00 phút      00 giây';
        },
        formattedValue() {
            let value = parseInt(this.inputData);
            if (!value) {
                value = '';
            }
            return value.toLocaleString("vi-VN");
        },
    },
    mounted() {
        this.runMethods()
        setInterval(() => {
            this.currentTime = this.$moment().format('LTS');
        });
    },
    methods: {
        async runMethods() {
            try {
                await this.getDataAsset();
            } catch (error) { }
        },
        formatInput(event) {
            this.inputData = this.formatNumber(event.target.value)
        },
        formateTime(time) {
            return this.$moment.utc(time).format("HH:mm:ss DD/MM/YYYY")
        },
        async getDataAsset() {
            this.asset_id = this.decode(this.$route.params.asset_id);
            const storedData = localStorage.getItem('auInfo')
            if (storedData) {
                var info = JSON.parse(storedData)
            }

            const res = await this.$axios.get('/asset/getAssetDetail?asset_id=' + this.asset_id, {
                headers: {
                    'x-access-token': info.accessToken
                }
            }).then(res => {
                this.dataAsset = res.data
                let startTime = new Date(this.dataAsset.auction_start_time).getTime();
                let endTime = new Date(this.dataAsset.end_time).getTime();
                let now = new Date().getTime();
                let countdown = startTime - now
                
                if (countdown - 25200000 !== 0 && countdown - 25200000 > 0) {
                    this.timeLeft = parseInt(countdown);
                    this.startCountdown(parseInt(countdown));
                    this.timeStart = true
                    if(countdown - 25200000 <= 1800000){
                        this.creatRoom = true
                    } else {
                        this.creatRoom = false
                    }
                } else {
                    countdown = endTime - now
                    this.timeLeft = parseInt(countdown);
                    this.startCountdown(parseInt(countdown));
                    this.timeStart = false
                }
                this.socket = this.$socket(res.data.auction_key, '', info.accessToken)
            }).catch(err => {
                if(err.response.status == 409) {
                    localStorage.removeItem('auInfo')
                    this.$toast.error('Tài khoản được đăng nhập từ thiết bị khác!')
                    this.$router.push('/loginAuctioneer')
                } else if(err.response.status == 401) {
                    localStorage.removeItem('auInfo')
                    this.$toast.error('Phiên đăng nhập đã hết hạn!')
                    this.$router.push('/loginAuctioneer')
                }
            })
        },
        createRoom(auction_key, auction_id) {
            // const key = '';
            // const token = JSON.parse(localStorage.getItem('auInfo')).accessToken;
            // this.$socket(auction_key, key, token).emit('createRoom')
            this.socket.connect()
            this.socket.emit('createRoom')
            this.$router.push('/auctioneer/auction-room/' + auction_id)
        },
    },
}
</script>
<style lang="css" scoped>
.wait_room {
    color: #515151;
    background-image: url('@/assets/images/logo_moreinfo.png');
    background-repeat: no-repeat;
    background-position: top;
}

.info {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.info p {
    min-width: 306px;
    margin: 0;
}

.info span {
    font-weight: 500;
}

.info span.highlight {
    color: #EE3439;
    font-size: 24px;
}

span.auction--success {
    color: #00B569;
    font-size: 20px;
    font-weight: 500;
}

.btn-link {
    border: 1px solid #515151;
    color: #515151;
    padding: 12px 32px;
    text-decoration: none;
    border-radius: 16px;
    font-weight: 500;
    display: inline-block;
}

.wait_room .more-info__ttl {
    font-weight: 500;
    font-size: 22px;
    line-height: 39.6px;
}

.wait_room .more-info li {
    padding: 5px 0;
}

.wait_room .list-info li {
    position: relative;
    margin-left: 33px;
}

.wait_room .list-info li::before {
    content: " ";
    background: url('@/assets/icon/Note.png');
    width: 25px;
    height: 25px;
    background-repeat: no-repeat;
    position: absolute;
    left: -33px;
    top: 3px;
}

.wait_room .list-info li a {
    text-decoration: none;
    color: #515151;
}

.list-info,
.list-info-cus {
    padding-left: 5px;
}

.wait_room .list-info-cus img {
    padding-right: 14px;
}

.wait_room .text-red {
    color: #EE3439;
    font-size: 16px;
    font-weight: 500;
    line-height: 28.8px;
    font-family: Roboto;
}

.show-time {
    position: relative;
    text-align: center;
    font-weight: 500;
    color: #EE3439;
    border: none;
    box-shadow: 2px 2px 2px 0px #00000040;
    border-radius: 40px;
    margin-bottom: 15px;
}

.show-time::before {
    content: "";
    position: absolute;
    background-image: url('@/assets/icon/clock.png');
    width: 75px;
    height: 61px;
    background-repeat: no-repeat;
    left: 0;
    top: 16px;
}

.show-time p:first-child {
    font-size: 22px;
}

.show-time p:last-child {
    font-size: 30px;
    font-family: Libre Bodoni;
    white-space: break-spaces;
}

.show-time p:nth-child(2) {
    font-size: 18px;
}

.show-time p {
    margin: 0;
}

.status_au {
    box-shadow: 0px 2px 10px 0px #0000001A;
    border-radius: 40px;
    text-align: center;
    position: relative;
}

.status_au::before {
    content: '';
    position: absolute;
    background-repeat: no-repeat;
    width: 41px;
    height: 41px;
    left: 5%;
    top: 25%;
}

.status_au p {
    color: #515151;
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 10px;
}

.status_au p:last-child {
    font-family: Libre Bodoni;
    font-weight: 700;
    font-size: 26px;
}

.status_au--success::before {
    background-image: url('@/assets/icon/Ok.png');
}

.status_au--fail::before {
    background-image: url('@/assets/front-end/icon/Group\ 831.png');
}

.status_au--success p:last-child {
    color: #00B569;
}

.status_au--fail p:last-child {
    color: #EE3439;
}

.btn-auction {
    background-color: #C22924;
    color: #FFFFFF;
    border: none;
    margin-left: 15px;
}

.btn-report {
    border-color: #EE3439;
    color: #EE3439;
    margin-left: 15px;
}

.image-status {
    position: absolute;
    z-index: 90;
    top: 5%;
    left: 5%;
}

@media (max-width: 1024px) {
    .info p {
        min-width: 240px;
    }

    .btn-link {
        padding: 12px 16px;
    }
}

@media (max-width: 768px) {

    .wait_room .title {
        font-size: 20px;
    }

    .wait_room .more-info,
    .wait_room .text-red {
        font-size: 14px;
    }

    .wait_room .more-info__ttl {
        font-size: 16px;
    }

    .info p {
        min-width: 306px;
    }

    .info span.highlight {
        font-size: 16px;
    }

    .btn-link {
        width: 100%;
    }

    .file .col-md-4 {
        width: 50%;
    }
}

@media (max-width: 425px) {
    .file .col-md-4 {
        width: 100%;
    }

    .info span.highlight {
        font-size: 18px;
    }

    .info p {
        min-width: 210px;
    }

    .info {
        font-size: 14px;
    }

    .show-time::before {
        display: none;
    }

    .show-time p:first-child {
        font-size: 18px;
    }

    .show-time p:nth-child(2) {
        font-size: 16px;
    }

    .show-time p:last-child {
        font-size: 22px;
    }

    .wait_room .title {
        font-size: 18px;
    }
}

@media (max-width: 375px) {

    .info span.highlight {
        font-size: 16px;
    }
}

@media (max-width: 320px) {
    .info p {
        min-width: 200px;
    }

    .info span.highlight,

    .info,
    .auction-box,
    .wait_room .more-info,
    .wait_room .text-red {
        font-size: 14px;
    }

    span.auction--success {
        font-size: 16px;
    }
}
</style>

<style lang="css">
.auction-box .form-group>div {
    display: flex;
    margin-top: 13px;
    justify-content: space-around;
}
</style>